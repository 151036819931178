<div class="dvb-wizard spinner-container" ng-class="{'spinning': vm.isLoading}">

    <div class="wizard-header big-abstand-bottom">
        <div ng-repeat="step in vm.steps" class="step">
            <div class="line line-left" ng-class="{'transparent': $first}"></div>
            <div class="icon"
                 ng-class="{'active': step.active, 'error': step.error, 'complete': step.complete}"
                 ng-click="vm.navigateTo(step)">
                <i ng-class="step.iconClass"></i>
            </div>
            <div class="line line-right" ng-class="{'transparent': $last}"></div>
            <div class="title" ng-bind="step.title | translate"></div>
        </div>
    </div>

    <div ng-repeat="step in vm.steps">
        <ng-form name="step.form" novalidate>
            <ng-transclude ng-transclude-slot="{{'step' + $index}}" ng-if="step.active"></ng-transclude>
        </ng-form>
    </div>

    <ul class="list-inline normal-abstand-top text-center">
        <li>
            <button type="button"
                    class="btn btn-link"
                    ng-disabled="vm.steps[0].active"
                    ng-click="vm.back()"
                    data-translate="EXTERNAL_ANMELDUNG.WIZARD.BACK">
            </button>
        </li>
        <li>
            <button ng-if="!vm.steps[vm.steps.length - 1].active"
                    type="button"
                    class="btn btn-primary"
                    ng-click="vm.next()"
                    data-translate="EXTERNAL_ANMELDUNG.WIZARD.NEXT">
            </button>
            <dvb-loading-button ng-if="vm.steps[vm.steps.length - 1].active"
                                type="submit"
                                spinner-right="true"
                                is-loading="vm.isLoading">
                <span data-translate="EXTERNAL_ANMELDUNG.ANMELDEN"></span>
            </dvb-loading-button>
        </li>
    </ul>
</div>

<div class="overlay-spinner" ng-show="!!vm.isLoading">
    <dvlib-spinner></dvlib-spinner>
</div>
