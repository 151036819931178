/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {TarifParameter} from '@dv/kitadmin/models';
import {TarifParameterHistoryEntry, TarifParameterValue} from '@dv/kitadmin/models';
import {TarifParameterType} from '@dv/shared/backend/model/tarif-parameter-type';
import type {Persisted} from '@dv/shared/code';
import {checkPresent, isPersisted} from '@dv/shared/code';
import angular from 'angular';

const directive: angular.IDirective = {
    restrict: 'E',
    transclude: {
        title: '?titleColumn',
    },
    replace: true,
    scope: {
        originalEntry: '<?',
        tarifParameter: '<',
        relevantParameter: '<?',
        onUpdate: '&',
        onCancel: '&',
    },
    template: require('./dvb-kind-tarife-parameter-form.html'),
    controllerAs: 'vm',
    bindToController: true,
};

export class DvbKindTarifeParameterForm implements angular.IOnInit {
    public static $inject: readonly string[] = ['errorService'];

    public originalEntry?: TarifParameterHistoryEntry;
    public tarifParameter!: Persisted<TarifParameter>[];
    public relevantParameter: string[] = [];
    public onUpdate!: (data: {tarifParameterHistoryEntry: TarifParameterHistoryEntry}) => void;
    public onCancel!: () => void;

    public tarifParameterHistoryEntry: TarifParameterHistoryEntry | null = null;
    public relevantParamValues: TarifParameterValue[] = [];
    public irrelevantParamValues: TarifParameterValue[] = [];

    public isNewEntity: boolean = false;
    public irrelevantParamsCollapsed: boolean = true;

    public constructor(private errorService: ErrorService) {
    }

    public $onInit(): void {
        this.isNewEntity = this.originalEntry === undefined || !isPersisted(this.originalEntry);
        this.originalEntry =
            this.originalEntry ?? new TarifParameterHistoryEntry(null, TarifParameterType.GLOBAL_PARAM);
        this.tarifParameterHistoryEntry = angular.copy(this.originalEntry);

        // adding all params as empty values
        this.addAllTarifParameter(this.tarifParameterHistoryEntry);
    }

    public addAllTarifParameter(entry: TarifParameterHistoryEntry): void {
        this.tarifParameter.forEach(tp => {
            const existingTarifParameter = entry.values.filter(v => v.parameter && v.parameter.id === tp.id);

            const relevantParam = this.relevantParameter.includes(tp.id);
            const listToAddTo = relevantParam ? this.relevantParamValues : this.irrelevantParamValues;

            if (existingTarifParameter.length !== 0) {
                listToAddTo.push(existingTarifParameter[0]);
                if (!relevantParam) {
                    this.irrelevantParamsCollapsed = false;
                }

                return;
            }

            const paramValue = new TarifParameterValue(null, tp.id, tp);
            entry.values.push(paramValue);
            listToAddTo.push(paramValue);
        });
    }

    public onSubmit(): void {
        const isValid = checkPresent(this.tarifParameterHistoryEntry).isValid();

        this.errorService.handleValidationError(isValid, 'TARIF.ERRORS.ERR_INCOMPLETE_PARAMETER_HISTORY_ENTRY');

        if (!isValid) {
            return;
        }

        const tarifParameterHistoryEntry = checkPresent(this.tarifParameterHistoryEntry).getCleanCopy();
        if (angular.equals(tarifParameterHistoryEntry, this.originalEntry)) {
            this.onCancel();

            return;
        }

        this.onUpdate({tarifParameterHistoryEntry});
    }

    public toggleIrrelevantParams(): void {
        this.irrelevantParamsCollapsed = !this.irrelevantParamsCollapsed;
    }
}

directive.controller = DvbKindTarifeParameterForm;
angular.module('kitAdmin').directive('dvbKindTarifeParameterForm', () => directive);
