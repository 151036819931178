import {NgClass} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    output,
    Signal,
    signal,
    untracked,
    viewChild,
} from '@angular/core';
import {outputFromObservable, takeUntilDestroyed, toObservable, toSignal} from '@angular/core/rxjs-interop';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {SearchEntityComponent} from '@dv/kitadmin/search';
import {MinutesInputDirective, OverlayDirective} from '@dv/kitadmin/ui';
import {
    ButtonComponent,
    ButtonListComponent,
    DatepickerTextfieldComponent,
    DvTimeFormatPipe,
    ElasticTextInputDirective,
    RequirePermissionAttributeDirective,
    RequirePermissionStructuralDirective,
    SpinnerComponent,
    ValidBisDirective,
    ValidVonDirective,
} from '@dv/shared/angular';
import {PrincipalService} from '@dv/shared/authentication/principal';
import {
    WorkTimeControllingServiceSaveManualEditRequestParams,
} from '@dv/shared/backend/api/work-time-controlling.service';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import {JaxPlannedAbsenceTermin} from '@dv/shared/backend/model/jax-planned-absence-termin';
import type {JaxWorkTimeControllingAbsence} from '@dv/shared/backend/model/jax-work-time-controlling-absence';
import {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import {JaxWorkTimeControllingTime} from '@dv/shared/backend/model/jax-work-time-controlling-time';
import {
    checkPresent,
    Displayable,
    DvbDateUtil,
    DvbUtil,
    isNullish,
    isPresent,
    nextId,
    Nullish,
    SearchResultEntry,
} from '@dv/shared/code';
import {shareState} from '@dv/shared/rxjs-utils';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {filter, map, merge, Subject, tap} from 'rxjs';
import {AngestellteWorkDay} from '../work-time-controlling-table/work-time-controlling-table.models';
import {AbsenceFormModel, toAbsenceModel, toAbsenceModels} from './absence-form.util';
import {ActualTimeFormModel, toActualTimeRangeModels} from './actual-time-form.util';
import {toPlannedTimes, WorkTimePlannedTime} from './planned-time.util';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'tr[dv-work-time-controlling-day-detail]',
    standalone: true,
    imports: [
        ElasticTextInputDirective,
        FormsModule,
        TooltipModule,
        ButtonComponent,
        TranslocoModule,
        RequirePermissionStructuralDirective,
        ButtonListComponent,
        MinutesInputDirective,
        NgClass,
        DvTimeFormatPipe,
        ValidVonDirective,
        ValidBisDirective,
        RequirePermissionAttributeDirective,
        OverlayDirective,
        SearchEntityComponent,
        DatepickerTextfieldComponent,
        SpinnerComponent,
    ],
    host: {
        '[class.ng-submitted]': 'formSubmitted()',
        '[class.form-container]': 'true',
    },
    templateUrl: './work-time-controlling-day-detail.component.html',
    styleUrl: './work-time-controlling-day-detail.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeControllingDayDetailComponent {

    public workDay = input.required<AngestellteWorkDay>();
    public isLoading = input.required<boolean>();
    public absenceTypes = input<JaxWorkTimeControllingAbsenceType[]>([]);
    public absenceTermine = input<JaxPlannedAbsenceTermin[]>([]);
    public kinderOrteById = input.required<{ [kinderOrtId: EntityId]: Displayable }>();
    public kinderOrtFraktionenById = input.required<{ [kinderOrtFraktionId: EntityId]: Displayable }>();
    public angestellteId = input.required<string>();
    public balanceCorrection = input<number | null>();
    public isBalanceCorrectionVisible = signal<boolean>(false);
    public balanceCorrectionHolidays = input<number | null>();
    public isBalanceCorrectionHolidaysVisible = signal<boolean>(false);

    /**
     * When true, displays a date input and an abort button instead of the reset button.
     */
    public forNewDay = input<boolean>(false);
    public availableDates = input<BackendLocalDate[]>([]);

    public readonly abort = output<void>();

    private errorService = inject(ErrorService);
    private preSelectedKinderOrt = inject(PrincipalService).getPrincipal().preSelectableKinderOrt;

    private form = viewChild.required(NgForm);

    public readonly submit$ = new Subject<void>();
    public readonly reset$ = new Subject<void>();

    private save$ = this.submit$.pipe(
        map((): undefined | WorkTimeControllingServiceSaveManualEditRequestParams => this.submit()),
        filter(isPresent),
        shareState(),
    );

    private formPristine$ = merge(this.reset$, this.save$).pipe(
        tap(() => this.form().form.markAsPristine()),
    );

    private formSubmitted$ = merge(
        this.submit$.pipe(map(() => true)),
        this.formPristine$.pipe(map(() => false)),
    );
    public formSubmitted = toSignal(this.formSubmitted$, {initialValue: false});

    public readonly save = outputFromObservable<WorkTimeControllingServiceSaveManualEditRequestParams>(this.save$);

    private standorte = computed(() => ({
        kinderOrteById: this.kinderOrteById(),
        fraktionenById: this.kinderOrtFraktionenById(),
    }));

    public plannedTimes: Signal<WorkTimePlannedTime[]> = computed(() => {
        return toPlannedTimes(this.workDay(), this.standorte());
    });

    private formInitializer = toSignal(toObservable(this.workDay).pipe(
        filter(() => !this.form().dirty),
    ));

    public state = computed(() => {
        // prevents overriding form state when the form is dirty
        const workDay = this.formInitializer();
        const standorte = untracked(() => this.standorte());
        const date = signal(workDay?.date);

        return {
            date,
            note: signal(workDay?.note ?? null),
            actualTimes: signal(toActualTimeRangeModels(workDay?.standortWorkDays ?? [], standorte)),
            absences: signal(toAbsenceModels(workDay?.absences ?? [], standorte)),
            balanceCorrection: signal(workDay?.balanceCorrection ?? null),
            isBalanceCorrectionVisible: signal(
                this.isBalanceCorrectionVisible() || isPresent(workDay?.balanceCorrection),
            ),
            balanceCorrectionHolidays: signal(workDay?.balanceCorrectionHolidays ?? null),
            isBalanceCorrectionHolidaysVisible: signal(
                this.isBalanceCorrectionHolidaysVisible() || isPresent(workDay?.balanceCorrectionHolidays),
            ),
            dateMoment: computed(() => DvbDateUtil.toMoment(date())),
        };
    });

    public constructor() {
        this.reset$.pipe(
            tap(() => {
                    this.errorService.clearAll();

                    const {
                        standortWorkDays,
                        note,
                        absences,
                        balanceCorrection,
                        balanceCorrectionHolidays,
                    } = this.workDay();

                    this.state().actualTimes.set(toActualTimeRangeModels(standortWorkDays, this.standorte()));
                    this.state().absences.set(toAbsenceModels(absences, this.standorte()));
                    this.state().balanceCorrection.set(balanceCorrection);
                    this.state().balanceCorrectionHolidays.set(balanceCorrectionHolidays);
                    this.state().note.set(note);
                },
            ),
            takeUntilDestroyed(),
        ).subscribe();
    }

    public addWorkDay(): void {
        const kinderOrt = isPresent(this.preSelectedKinderOrt) ?
            SearchResultEntry.apiResponseTransformer(this.preSelectedKinderOrt) :
            undefined;

        this.state().actualTimes.update((workDays: ActualTimeFormModel[]) => [
            ...workDays,
            {
                uiId: nextId(),
                editPermitted: true,
                overlayOpen: signal(false),
                kinderOrt,
                kinderOrtFraktion: undefined,
                timeRange: {von: undefined, bis: undefined},
            },
        ]);
    }

    public addAbsence(): void {
        const kinderOrt = isPresent(this.preSelectedKinderOrt) ?
            SearchResultEntry.apiResponseTransformer(this.preSelectedKinderOrt) :
            undefined;

        this.state().absences.update((absences: AbsenceFormModel[]) => [
            ...absences,
            toAbsenceModel(kinderOrt),
        ]);
    }

    public removeTimeRange(toRemove: ActualTimeFormModel): void {
        this.state().actualTimes.update(models => models.filter(m => m.uiId !== toRemove.uiId));
    }

    public removeAbsence(toRemove: AbsenceFormModel): void {
        this.state().absences.update(models => models.filter(m => m.uiId !== toRemove.uiId));
    }

    public removeCorrection(): void {
        this.state().balanceCorrection.set(null);
        this.state().isBalanceCorrectionVisible.set(false);
    }

    public removeCorrectionHolidays(): void {
        this.state().balanceCorrectionHolidays.set(null);
        this.state().isBalanceCorrectionHolidaysVisible.set(false);
    }

    private submit(): WorkTimeControllingServiceSaveManualEditRequestParams | undefined {
        this.errorService.clearAll();
        const state = this.state();

        const isFormValid = this.form().valid ?? false;
        const isFormValidWithData = isFormValid && this.isFormValidForNewDay();

        this.errorService.handleValidationError(isFormValidWithData, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!isFormValidWithData) {
            return;
        }

        let balanceCorrection: number | Nullish = state.balanceCorrection();
        if (isNullish(balanceCorrection) || balanceCorrection === 0) {
            balanceCorrection = undefined;
        }
        let balanceCorrectionHolidays: number | Nullish = state.balanceCorrectionHolidays();
        if (isNullish(balanceCorrectionHolidays) || balanceCorrectionHolidays === 0) {
            balanceCorrectionHolidays = undefined;
        }

        const standortTimes: JaxWorkTimeControllingTime[] = state.actualTimes()
            .filter(a => a.editPermitted)
            .map(a => ({
                kinderOrtId: a.kinderOrt?.id,
                kinderOrtFraktionId: a.kinderOrtFraktion?.id,
                actualTime: {von: checkPresent(a.timeRange.von), bis: checkPresent(a.timeRange.bis)},
            }));

        const absences: JaxWorkTimeControllingAbsence[] = state.absences()
            .filter(a => a.editPermitted)
            .map(a => ({
                absenceTypeId: checkPresent(a.absenceTypeId),
                kinderOrtId: a.kinderOrt?.id,
                von: a.von(),
                bis: a.bis(),
                minutes: a.duration().minutes(),
            }));

        return {
            angestellteId: this.angestellteId(),
            jaxAngestellteEditDay: {
                angestellteId: this.angestellteId(),
                date: checkPresent(state.date()),
                note: state.note() ?? undefined,
                standortTimes,
                absences,
                balanceCorrection,
                balanceCorrectionHolidays,
            },
        };
    }

    private isFormValidForNewDay(): boolean {
        if (!this.forNewDay()) {
            return true;
        }

        const state = this.state();

        // new day form is only valid, if some data has been entered
        return DvbUtil.isNotEmptyString(state.note()?.trim())
            || state.actualTimes().length > 0
            || state.absences().length > 0
            || (isPresent(state.balanceCorrection()) && state.balanceCorrection() !== 0)
            || (isPresent(state.balanceCorrectionHolidays()) && state.balanceCorrectionHolidays() !== 0);
    }
}
