import {ChangeDetectionStrategy, Component, computed, inject, signal, ViewChild} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {
    Click2EditComponent,
    Click2EditDirective,
    DatepickerTextfieldComponent,
    EditModeDirective,
} from '@dv/shared/angular';
import {WorkTimeControllingConfigService} from '@dv/shared/backend/api/work-time-controlling-config.service';
import {DvbRestUtil} from '@dv/shared/code';
import {TranslocoPipe} from '@jsverse/transloco';

@Component({
    selector: 'dv-work-time-controlling-config',
    standalone: true,
    imports: [
        TranslocoPipe,
        FormsModule,
        DatepickerTextfieldComponent,
        Click2EditComponent,
        EditModeDirective,
        Click2EditDirective,
    ],
    templateUrl: './work-time-controlling-config.component.html',
    styles: `
		:host {
			display: block;
		}
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeControllingConfigComponent {

    private errorService = inject(ErrorService);
    private configService = inject(WorkTimeControllingConfigService);

    @ViewChild('form', {static: true}) private ngForm!: NgForm;

    private config = toSignal(this.configService.get$());

    public state = computed(() => {
        return {
            lockedUntil: signal(this.config()?.lockedUntil),
            monthLockedAfterDays: signal(this.config()?.monthLockedAfterDays),
        };
    });

    private requestParams = computed(() => {
        return {
            jaxWorkTimeControllingConfig: {
                lockedUntil: this.state().lockedUntil(),
                monthLockedAfterDays: this.state().monthLockedAfterDays(),
            },
        };
    });

    public submitForm(): void {
        this.errorService.clearAll();
        if (!this.ngForm.valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        const backendRequestParams = {
            jaxWorkTimeControllingConfig: {
                lockedUntil: DvbRestUtil.momentToLocalDate(
                    this.requestParams().jaxWorkTimeControllingConfig.lockedUntil) ?? undefined,
                monthLockedAfterDays: this.requestParams().jaxWorkTimeControllingConfig.monthLockedAfterDays,
            },
        };

        this.configService.update$(backendRequestParams).subscribe();
    }
}
