/*
 * Copyright © 2025 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {WritableSignal} from '@angular/core';
import {signal} from '@angular/core';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import type {Displayable} from '@dv/shared/code';
import {nextId, SearchResultEntry, TimeRangeUtil} from '@dv/shared/code';
import type {AngestellteStandortWorkDay} from '../work-time-controlling-table/work-time-controlling-table.models';

export type ActualTimeFormModel = {
    uiId: string;
    editPermitted: boolean;
    overlayOpen: WritableSignal<boolean>;
    kinderOrt: SearchResultEntry | undefined;
    kinderOrtFraktion: SearchResultEntry | undefined;
    timeRange: Partial<JaxTimeRange>;
};

export function toActualTimeRangeModels(
    standortWorkDays: AngestellteStandortWorkDay[],
    {kinderOrteById, fraktionenById}: {
        kinderOrteById: { [kinderOrtId: EntityId]: Displayable };
        fraktionenById: { [kinderOrtFraktionId: EntityId]: Displayable };
    },
): ActualTimeFormModel[] {

    const result = standortWorkDays.flatMap(swd => {
        return (swd.actualTimeRanges ?? [])
            .map((a): ActualTimeFormModel => {
                const kinderOrt = swd.kinderOrtId ?
                    new SearchResultEntry('KITA',
                        swd.kinderOrtId,
                        kinderOrteById[swd.kinderOrtId].displayName) :
                    undefined;

                const kinderOrtFraktion = swd.kinderOrtFraktionId ?
                    new SearchResultEntry(
                        'GRUPPE',
                        swd.kinderOrtFraktionId,
                        fraktionenById[swd.kinderOrtFraktionId].getDisplayName()) :
                    undefined;

                return {
                    uiId: nextId(),
                    editPermitted: swd.editPermitted,
                    overlayOpen: signal(false),
                    kinderOrt,
                    kinderOrtFraktion,
                    timeRange: {von: a.von, bis: a.bis},
                };
            });
    });

    result.sort((a, b) => TimeRangeUtil.BACKEND_TIME_RANGE_COMPARATOR(a.timeRange, b.timeRange));

    return result;
}
