/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import {GEMO_WIN_EXPORT} from '@dv/kitadmin/gemo-win-export';
import {GROUP_ADMIN_EINSTELLUNGEN} from '@dv/kitadmin/group-admin-einstellungen';
import {KIND_ABWEICHUNGS_MELDUNGEN} from '@dv/kitadmin/kind-abweichungs-meldungen';
import type {KinderOrt} from '@dv/kitadmin/models';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import type {UIRouterGlobals} from '@uirouter/core';
import angular from 'angular';
import moment from 'moment';
import {KINDERORT_EXTERNAL_ANMELDUNG_STATES} from '../../kinderort-external-anmeldung-states';
import {KINDERORT_PERSONAL_STATES} from '../../kinderort-personal-states';
import {KINDERORT_SETTING_STATES} from '../../kinderort-settings-states';
import {KINDERORT_WORK_TIME_CONTROLLING_STATES} from '../../kinderort-work-time-controlling-states';
import type {KinderOrtWocheService} from '../../service/kinderOrtWocheService';

angular.module('kitAdmin').controller('KitaMainCtrl', kitaMainController);

kitaMainController.$inject = [
    'kita',
    'hasMandantBetreuungsfaktorRegeln',
    'hasMandantSchliesstage',
    'wartelisteCount',
    '$scope',
    'kinderOrtWocheService',
    'errorService',
    '$uiRouterGlobals',
    'authStore',
];

export interface KitaMainCtrlScope extends angular.IScope {
    firstOfWeek: moment.Moment;
    lastOfWeek: moment.Moment;
    $uiRouterGlobals: UIRouterGlobals;

    wartelisteCount: number;

    weekPicker: {
        gueltigAb: moment.Moment | null;
        gueltigBis: moment.Moment | null;
        firstOfWeek: moment.Moment | null;
    };

    hasMandantBetreuungsfaktorRegeln: boolean;
    hasMandantSchliesstage: boolean;
    hasPersonalStates: boolean;

    kita: KinderOrt;

    settingsStates: typeof KINDERORT_SETTING_STATES;
    personalStates: typeof KINDERORT_PERSONAL_STATES;
    kinderortWorkTimeControllingStates: typeof KINDERORT_WORK_TIME_CONTROLLING_STATES;
    externalAnmeldungStates: typeof KINDERORT_EXTERNAL_ANMELDUNG_STATES;
    kindAbweichungsMeldungState: typeof KIND_ABWEICHUNGS_MELDUNGEN;
    groupAdminEinstellungenState: typeof GROUP_ADMIN_EINSTELLUNGEN;
    gemoWinExport: typeof GEMO_WIN_EXPORT;

    showDatePicker: () => boolean;

    weekPickerDateChanged: () => void;

    setWeek: (newFirstOfWeek: moment.Moment) => void;
}

function kitaMainController(
    kita: KinderOrt,
    hasMandantBetreuungsfaktorRegeln: boolean,
    hasMandantSchliesstage: boolean,
    wartelisteCount: number,
    $scope: KitaMainCtrlScope,
    kinderOrtWocheService: KinderOrtWocheService,
    errorService: ErrorService,
    $uiRouterGlobals: UIRouterGlobals,
    authStore: AuthStore,
): void {

    $scope.$uiRouterGlobals = $uiRouterGlobals;
    $scope.hasMandantBetreuungsfaktorRegeln = hasMandantBetreuungsfaktorRegeln;
    $scope.hasMandantSchliesstage = hasMandantSchliesstage;
    $scope.hasPersonalStates = authStore.hasPermission(PERMISSION.PERSONAL.VIEW + kita.id!);

    $scope.wartelisteCount = wartelisteCount;

    $scope.showDatePicker = showDatePicker;

    $scope.kita = kita;

    $scope.settingsStates = KINDERORT_SETTING_STATES;
    $scope.personalStates = KINDERORT_PERSONAL_STATES;
    $scope.kinderortWorkTimeControllingStates = KINDERORT_WORK_TIME_CONTROLLING_STATES;
    $scope.externalAnmeldungStates = KINDERORT_EXTERNAL_ANMELDUNG_STATES;
    $scope.kindAbweichungsMeldungState = KIND_ABWEICHUNGS_MELDUNGEN;
    $scope.groupAdminEinstellungenState = GROUP_ADMIN_EINSTELLUNGEN;
    $scope.gemoWinExport = GEMO_WIN_EXPORT;

    const kitaGueltigkeit = {
        gueltigAb: kita.getGueltigAb(),
        gueltigBis: kita.getGueltigBis(),
    };

    $scope.weekPicker = {
        gueltigAb: kitaGueltigkeit.gueltigAb,
        gueltigBis: kitaGueltigkeit.gueltigBis,
        firstOfWeek: $scope.firstOfWeek,
    };

    $scope.weekPickerDateChanged = (): void => {
        const weekpickerFirstOfWeek = $scope.weekPicker.firstOfWeek!;
        if (!weekpickerFirstOfWeek.isSame($scope.firstOfWeek)) {
            $scope.setWeek(weekpickerFirstOfWeek);
        }
    };

    $scope.setWeek = (newFirstOfWeek): void => {
        const kitaGueltigAtFirstOfWeek = DvbDateUtil.isGueltigOn(kitaGueltigkeit, newFirstOfWeek);
        errorService.handleValidationError(kitaGueltigAtFirstOfWeek, 'KINDERORT.KITA_GUELTIGKEIT_INVALID', {
            gueltigAb: $scope.kita.getGueltigAb()!.format('D. MMMM YYYY'),
            gueltigBis: $scope.kita.getGueltigBis()!.format('D. MMMM YYYY'),
        });

        if (!kitaGueltigAtFirstOfWeek) {
            return;
        }

        $scope.firstOfWeek = DvbDateUtil.startOfWeek(moment(newFirstOfWeek));
        $scope.lastOfWeek = DvbDateUtil.endOfWeek(moment(newFirstOfWeek));
        $scope.weekPicker.firstOfWeek = $scope.firstOfWeek;
        kinderOrtWocheService.setLastFirstOfWeek($scope.firstOfWeek, checkPresent($scope.kita.id));
        // whenever the week changes, all pending requests are cancelled. Sub-components should initialise based
        // on their $onChanges hook, triggered by the new object for 'firstOfWeek'.
        kinderOrtWocheService.cancelPendingHttpRequests();
    };

    const datePickerStates = [
        'base.kinderort.uebersicht',
        'base.kinderort.kinder.warteliste',
        'base.kinderort.kinder.belegung',
    ];

    function showDatePicker(): boolean {
        return datePickerStates.includes($uiRouterGlobals.current.name ?? '');
    }

    function setStartWeek(): void {
        const gueltigAb = kitaGueltigkeit.gueltigAb!;
        const gueltigBis = kitaGueltigkeit.gueltigBis!;
        const initFirstOfWeek = kinderOrtWocheService.getLastFirstOfWeek(checkPresent($scope.kita.id));
        if (DvbDateUtil.isValidMoment(gueltigAb) && initFirstOfWeek.isBefore(gueltigAb)) {
            // In die Zukunft springen, so dass die Kita zu ihrem Eroeffnungsdatum angezeigt wird.
            $scope.setWeek(gueltigAb);
        } else if (DvbDateUtil.isValidMoment(gueltigBis) && initFirstOfWeek.isAfter(gueltigBis)) {
            // Auf das Ende der Kita Gültigkeit springen
            $scope.setWeek(gueltigBis);
        } else {
            $scope.setWeek(initFirstOfWeek);
        }
    }

    setStartWeek();
}
