/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ITimelineEntry} from '@dv/shared/timeline';
import angular from 'angular';
import type {ITimelineController} from '../../models/ITimelineController';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        entry: '<',
        timelineController: '<',
    },
    template: require('./dvb-timeline-entry-icon.html'),
    controllerAs: 'vm',
};

export class DvbTimelineEntryIcon implements angular.IController {
    public static $inject: readonly string[] = [];

    public entry!: ITimelineEntry;
    public timelineController!: ITimelineController<ITimelineEntry>;

    public iconHTML: string = '';
    public iconTooltipText: string = '';

    public $onInit(): void {
        this.iconHTML = this.timelineController.getIconHTML(this.entry);
        this.iconTooltipText = this.timelineController.getIconTooltipText(this.entry);
    }
}

componentConfig.controller = DvbTimelineEntryIcon;
angular.module('kitAdmin').component('dvbTimelineEntryIcon', componentConfig);
