<ng-container *transloco="let t">
    <div class="modal-body">
        <h1>{{ t('COMMUNICATION.BULK_EMAILS.HISTORY.RECIPIENTS') }}</h1>

        @for (kontaktperson of kontaktpersonen; track kontaktperson.id) {
            <div>
                <div class="small-abstand-top">
                    <a [uiSref]="'base.kontaktperson.profil'"
                       [uiParams]="{kontaktpersonId: kontaktperson.id}"
                       href
                       (click)="hide()">
                        {{ kontaktperson | displayName }}
                    </a>
                    <p>{{ kontaktperson.email }}</p>
                </div>
            </div>
        }
    </div>
    <div class="modal-footer normal-abstand-top">
        <button type="button" class="btn btn-link" (click)="hide()">
            {{ t('COMMON.ABBRECHEN') }}
        </button>
    </div>
</ng-container>
