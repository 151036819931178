/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    BetreuungsGutschein,
    BetreuungsGutscheinGroup,
    Kind,
    KinderOrt,
    KinderOrtId,
    PlatzTyp,
    StundenKontingent,
    TarifParameter,
    TarifParameterHistoryEntries,
} from '@dv/kitadmin/models';
import {TarifParameterHistoryEntry} from '@dv/kitadmin/models';
import type {DialogService} from '@dv/kitadmin/ui';
import type {AuthStore} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {FormContext} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil, END_OF_TIME, ERROR_CODE} from '@dv/shared/code';
import {GENERAL_SUFFIX} from '@dv/shared/roles';
import type {StateService} from '@uirouter/core';
import angular from 'angular';
import type moment from 'moment';
import type {Observable} from 'rxjs';
import {from, Subject, take, tap} from 'rxjs';
import type {BetreuungsGutscheinService} from '../../../common/service/rest/kind/betreuungsGutscheinService';
import type {KindService} from '../../../common/service/rest/kind/kindService';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import type {StundenKontingentService} from '../../../kinderort/service/stundenKontingentService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        kind: '<',
        kindParameter: '<',
        tarifParameter: '<',
        betreuungsGutscheineGroups: '<',
        stundenKontingente: '<',
    },
    template: require('./dvb-kind-tarife.html'),
    controllerAs: 'vm',
};

export interface StundenKontingenteByPlatzTyp {
    platzTyp: PlatzTyp;
    stundenKontingente: StundenKontingent[];
}

export class DvbKindTarife implements angular.IController {

    public static $inject: readonly string[] = [
        'kindService',
        'betreuungsGutscheinService',
        '$state',
        '$translate',
        'errorService',
        'kinderOrtService',
        '$q',
        'dialogService',
        'stundenKontingentService',
        'authStore',
    ];

    public kind!: Kind;
    public kindParameter!: TarifParameterHistoryEntries;
    public tarifParameter: TarifParameter[] = [];
    public betreuungsGutscheineGroups: BetreuungsGutscheinGroup[] = [];
    public stundenKontingente: StundenKontingent[] = [];

    public newParamFormIsVisible: boolean = false;
    public newParamFormValue?: TarifParameterHistoryEntry;
    public newGutscheinFormIsVisible: boolean = false;
    public newStundenKontingentFormIsVisible: boolean = false;
    public kinderOrte: { [k in KinderOrtId]: KinderOrt } = {};
    public betreuungsGutscheineByKinderOrt: { [k in KinderOrtId]: BetreuungsGutscheinGroup[] } = {};
    public hasBetreuungsGutscheine: boolean = false;
    public stundenKontingenteByKinderOrt: { [k in KinderOrtId]: StundenKontingenteByPlatzTyp[] } = {};

    public showAnwesenheitsSoll: boolean = false;

    public constructor(
        private readonly kindService: KindService,
        private readonly betreuungsGutscheinService: BetreuungsGutscheinService,
        private readonly $state: StateService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly errorService: ErrorService,
        private readonly kinderOrtService: KinderOrtService,
        private readonly $q: angular.IQService,
        private readonly dialogService: DialogService,
        private readonly stundenKontingentService: StundenKontingentService,
        private readonly authStore: AuthStore,
    ) {
    }

    public $onInit(): void {
        this.showAnwesenheitsSoll = this.authStore.hasAnyPermission([
            PERMISSION.FEATURE.ANWESENHEITS_SOLL_JAEHRLICH_KSA_ZWAERGLIHUUS + GENERAL_SUFFIX,
            PERMISSION.FEATURE.ANWESENHEITS_SOLL_MONATLICH_KSA_ZOBRA + GENERAL_SUFFIX,
        ]);
    }

    public $onChanges(changesObj: angular.IOnChangesObject): void {
        if (changesObj.betreuungsGutscheineGroups) {
            this.betreuungsGutscheineByKinderOrt = {};
            this.fetchUniqueKitasForBetreuungsGutscheine().then(kinderOrte => {
                kinderOrte.forEach(kinderOrt => {
                    const id = checkPresent(kinderOrt.id);

                    this.kinderOrte[id] = kinderOrt;
                    this.betreuungsGutscheineByKinderOrt[id] = this.getBetreuungsGutscheineForKinderOrt(id);
                    this.hasBetreuungsGutscheine = true;
                });
            });
        }
        if (!changesObj.stundenKontingente) {
            return;
        }

        this.stundenKontingenteByKinderOrt = {};
        this.fetchUniqueKitasForStundenKontingente().then(kinderOrte => {
            kinderOrte.forEach(kinderOrt => {
                const id = checkPresent(kinderOrt.id);

                this.kinderOrte[id] = kinderOrt;
                const kontingenteForKinderOrt = this.getStundenKontingenteForKinderOrt(id);

                const byPlatzTyp: StundenKontingenteByPlatzTyp[] = [];
                kontingenteForKinderOrt.forEach(currentValue => {
                    const found = byPlatzTyp.find(v => v.platzTyp === currentValue.kontingentId);
                    if (found) {
                        found.stundenKontingente.push(currentValue);

                        return;
                    }

                    const value = {platzTyp: currentValue.kontingentId, stundenKontingente: [currentValue]};
                    byPlatzTyp.push(value);
                });

                byPlatzTyp.forEach(e => DvbDateUtil.sortLimitedEntitiesByGueltigAbDesc(e.stundenKontingente));

                this.stundenKontingenteByKinderOrt[id] = byPlatzTyp;
            });
        });
    }

    public cancelParam(): void {
        this.newParamFormValue = undefined;
        this.newParamFormIsVisible = false;
    }

    public cancelGutschein(): void {
        this.newGutscheinFormIsVisible = false;
    }

    public cancelStundenKontingent(): void {
        this.newStundenKontingentFormIsVisible = false;
    }

    public createParam(entry: TarifParameterHistoryEntry): void {
        this.cancelParam();
        this.kindService.createTarifParameterHistoryEntry(checkPresent(this.kind.id), entry)
            .finally(() => this.$state.reload());
    }

    public revertParam(entry: TarifParameterHistoryEntry): void {
        this.kindService.extendTarifParameterHistoryEntry(checkPresent(this.kind.id), checkPresent(entry.id))
            .finally(() => this.$state.reload());
    }

    public confirmDeleteParam(entry: TarifParameterHistoryEntry): void {
        this.dialogService.openDeleteDialog({
            entityText: 'KIND.LEISTUNGEN.KIND_TARIF_PARAMETER_HISTORY_ENTRY',
            confirm: () => from(this.deleteParam(entry))
                .pipe(take(1), tap(() => this.$state.reload())),
        });
    }

    public terminateKindParameterModal(): void {
        const beendigungAction = (endeDatum: moment.Moment): Observable<unknown> => {
            this.errorService.clearAll();

            return from(this.kindService.terminateKindParameter(checkPresent(this.kind.id), endeDatum)
                .then(() => this.$state.reload())).pipe(take(1));
        };

        this.dialogService.openDateDialog({
            title: 'KIND.LEISTUNGEN.KIND_TARIF_PARAMETER_HISTORY_ENTRY_TERMINATE_HEADING',
            confirm: endeDatum => {
                const parameterNachEndeDatum = this.kindParameter.entries.filter(
                    parameter => checkPresent(parameter.gueltigAb).isAfter(endeDatum));

                if (parameterNachEndeDatum.length) {
                    const dialog$ = new Subject<void>();

                    this.dialogService.openConfirmDialog({
                        title: 'KIND.LEISTUNGEN.CONFIRM_TARIFE_PARAMETER_BEENDEN',
                        confirmActionText: 'COMMON.BEENDEN',
                        confirm: () => beendigungAction(endeDatum).pipe(tap(() => dialog$.next())),
                        cancel: () => dialog$.next(),
                    });

                    return dialog$.pipe(take(1));
                }

                return beendigungAction(endeDatum);
            },
        });
    }

    public createGutschein(betreuungsGutschein: BetreuungsGutschein): void {
        this.kindService.createBetreuungsGutschein(checkPresent(this.kind.id), betreuungsGutschein).then(() => {
            this.cancelGutschein();
            this.$state.reload();
        });
    }

    public confirmDeleteGutschein(betreuungsGutscheinGroup: BetreuungsGutscheinGroup): void {
        const entityText = betreuungsGutscheinGroup.schemaVersion ?
            this.$translate.instant('KIND.KITAX.MF_VERFUEGUNG',
                {type: betreuungsGutscheinGroup.schemaVersion.type},
                'messageformat') :
            this.$translate.instant('COMMON.BETREUUNGSGUTSCHEIN');

        this.dialogService.openDeleteDialog({
            entityText,
            confirm: () => from(
                this.deleteGutschein(betreuungsGutscheinGroup)
                    .then(() => this.$state.reload()),
            ),
        });
    }

    public createStundenKontingent(stundenKontingent: StundenKontingent, formContext: FormContext): void {
        formContext.startLoading();
        this.stundenKontingentService.create(stundenKontingent)
            .then(() => {
                this.cancelStundenKontingent();
                this.$state.reload();
            })
            .catch(err => {
                if (err && err.errorCode === ERROR_CODE.RULE_VIOLATION) {
                    return this.$q.resolve();
                }

                return this.$q.reject(err);
            })
            .finally(() => {
                formContext.finishLoading();
            });
    }

    public removeStundenKontingent(stundenKontingent: StundenKontingent, entry: StundenKontingenteByPlatzTyp): void {
        DvbUtil.removeFromArray(stundenKontingent, this.stundenKontingente);
        if (entry.stundenKontingente.length === 0) {
            DvbUtil.removeFromArray(entry, this.stundenKontingenteByKinderOrt[stundenKontingent.kinderOrtId!]);
        }
    }

    public duplicate(entry: TarifParameterHistoryEntry): void {
        this.newParamFormIsVisible = true;
        const copy = entry.getCleanCopy();
        this.newParamFormValue = new TarifParameterHistoryEntry(
            null,
            copy.dtype,
            null,
            END_OF_TIME,
            copy.values,
            null,
            null,
            null,
            null);
    }

    private getBetreuungsGutscheineForKinderOrt(id: KinderOrtId): BetreuungsGutscheinGroup[] {
        return this.betreuungsGutscheineGroups.filter(group => group.kinderOrtId === id);
    }

    private getStundenKontingenteForKinderOrt(id: KinderOrtId): StundenKontingent[] {
        return this.stundenKontingente.filter(kontingent => kontingent.kinderOrtId === id);
    }

    private fetchUniqueKitasForBetreuungsGutscheine(): angular.IPromise<KinderOrt[]> {
        const kitaIds = DvbUtil.uniqueArray(this.betreuungsGutscheineGroups.map(grp =>
            checkPresent(grp.kinderOrtId)));

        return this.fetchKitas(kitaIds);
    }

    private fetchUniqueKitasForStundenKontingente(): angular.IPromise<KinderOrt[]> {
        const kitaIds = DvbUtil.uniqueArray(this.stundenKontingente.map(k => checkPresent(k.kinderOrtId)));

        return this.fetchKitas(kitaIds);
    }

    private fetchKitas(ids: KinderOrtId[]): angular.IPromise<KinderOrt[]> {
        const promises = ids.map(kinderOrtId => this.kinderOrtService.get(kinderOrtId,
            {cache: true, includes: '(kontingente)'}));

        return this.$q.all(promises);
    }

    private deleteParam(entry: TarifParameterHistoryEntry): angular.IPromise<unknown> {
        this.errorService.clearAll();

        return this.kindService.deleteTarifParameterHistoryEntry(checkPresent(this.kind.id),
            checkPresent(entry.id));

    }

    private deleteGutschein(betreuungsGutscheinGroup: BetreuungsGutscheinGroup): angular.IPromise<unknown> {
        this.errorService.clearAll();

        if (betreuungsGutscheinGroup.kiTaxId) {
            // imported gutschein --> delete the whole verfuegung
            return this.betreuungsGutscheinService.deleteKiTaxVerfuegung(betreuungsGutscheinGroup.kiTaxId)
                .then(() => this.$state.reload());
        }

        const betreuungsGutscheinId = checkPresent(betreuungsGutscheinGroup.betreuungsGutscheine[0].id);

        // manually created gutschein --> there is no verfuegung, just delete the gutschein
        return this.betreuungsGutscheinService.deleteBetreuungsGutschein(betreuungsGutscheinId);
    }
}

componentConfig.controller = DvbKindTarife;

angular.module('kitAdmin').component('dvbKindTarife', componentConfig);
