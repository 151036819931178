<ng-container ngForm *transloco="let t">
    <td colspan="2" class="action-column">
            @if (forNewDay()) {
                <dvlib-datepicker-textfield
                    name="date"
                    [(ngModel)]="state().date"
                    class="small-abstand-bottom"
                    required
                    [showTodayButton]="false"
                    [placeholder]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.DATE')"
                    [datesEnabled]="availableDates()"/>
            }
            <textarea type="text"
                      [rows]="5"
                      [(ngModel)]="state().note"
                      [dvlibRequirePermission]="'work_time_controlling:work_time_controlling_table_edit:general'"
                      class="form-control"
                      name="note"
                      dvlibElasticTextInput
                      [placeholder]="t('NOTIZ.PLURAL')"
                      [attr.aria-label]="t('NOTIZ.PLURAL')"
                      [tooltip]="t('NOTIZ.PLURAL')">
            </textarea>

        <div class="actions"
             *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
            <dvlib-button-list>
                <button dvlibButton="primary" (click)="submit$.next()" [disabled]="isLoading()">
                    {{ t('COMMON.SPEICHERN') }}
                </button>
                @if (forNewDay()) {
                    <button dvlibButton="link-button" (click)="abort.emit()">
                        {{ t('COMMON.ABBRECHEN') }}
                    </button>
                } @else {
                    <button dvlibButton="link-button" (click)="reset$.next()">
                        {{ t('COMMON.RESET') }}
                    </button>
                }
                <dvlib-spinner [show]="isLoading()"/>
            </dvlib-button-list>
        </div>
    </td>

    <!-- Geplante Arbeitszeit -->
    <td>
        @for (plannedTime of plannedTimes(); track plannedTime.uiId) {
            <div class="work-time-controlling-card small-abstand-bottom"
                 [ngClass]="plannedTime.type === 'TERMIN' ? 'termin' : 'worktime'">
                <div class="time small-abstand-bottom">
                    <div>{{ plannedTime.timeRange.von | dvTimeFormat }}</div>
                    <div>{{ plannedTime.timeRange.bis | dvTimeFormat }}</div>
                </div>
                <div class="standort-readonly">
                    <span class="standort-content">{{ plannedTime.name }}</span>
                </div>
            </div>
        }
    </td>

    <!-- Ist Arbeitszeit -->
    <td>
        @for (actualTimeRange of state().actualTimes(); track actualTimeRange.uiId; let index = $index) {
            <fieldset class="work-time-controlling-card small-abstand-bottom">
                <legend class="sr-only">
                    {{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.IST_LEGEND', {index: index + 1}) }}
                </legend>
                @if (actualTimeRange.editPermitted) {
                    <button dvlibButton="link-button"
                            class="close-button"
                            [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_IST')"
                            [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_IST')"
                            (click)="removeTimeRange(actualTimeRange)">
                        <i class="custom-icon custom-icon-close-thin"></i>
                    </button>
                }
                <div class="time-inputs small-abstand-bottom">
                    <input type="time"
                           [name]="'von_' + index"
                           [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.IST_VON')"
                           [disabled]="!actualTimeRange.editPermitted"
                           [(ngModel)]="actualTimeRange.timeRange.von"
                           validVon
                           [dateStart]="state().dateMoment()"
                           [dateEnd]="state().dateMoment()"
                           [timeEnd]="actualTimeRange.timeRange.bis"
                           required
                           class="form-control input-sm no-margin"/>
                    <input type="time"
                           [name]="'bis_' + index"
                           [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.IST_BIS')"
                           [disabled]="!actualTimeRange.editPermitted"
                           [(ngModel)]="actualTimeRange.timeRange.bis"
                           validBis
                           [timeStart]="actualTimeRange.timeRange.von"
                           [dateStart]="state().dateMoment()"
                           [dateEnd]="state().dateMoment()"
                           required
                           class="form-control input-sm no-margin"/>
                </div>
                <button dvlibButton="link-button"
                        class="standort-selection"
                        [disabled]="!actualTimeRange.editPermitted"
                        dvOverlay
                        [overlayTemplate]="selectStandortForTime"
                        [overlayContext]="{$implicit: actualTimeRange}"
                        [overlayOpen]="actualTimeRange.overlayOpen()"
                        (closeOverlay)="actualTimeRange.overlayOpen.set(false)"
                        (click)="actualTimeRange.overlayOpen.set(true)">
                        <span class="button-content">
                            @if (actualTimeRange.kinderOrt) {
                                <span>{{ actualTimeRange.kinderOrt.text }}</span>
                            }
                            @if (actualTimeRange.kinderOrt && actualTimeRange.kinderOrtFraktion) {
                                <span>, </span>
                            }
                            @if (actualTimeRange.kinderOrtFraktion) {
                                <span>{{ actualTimeRange.kinderOrtFraktion.text }}</span>
                            }

                            @if (!actualTimeRange.kinderOrt && !actualTimeRange.kinderOrtFraktion) {
                                + {{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.SELECT_LOCATION') }}
                            }
                        </span>
                </button>
            </fieldset>
        }
        <div class="row" *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
            <div class="col-md-12 text-center">
                <button dvlibButton="link-button"
                        [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_IST')"
                        [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_IST')"
                        (click)="addWorkDay()">
                    <i class="custom-icon custom-icon-plus-thin"></i>
                </button>
            </div>
        </div>
    </td>

    <!-- Absenzen -->
    <td>
        @for (absence of state().absences(); track absence.uiId; let absenceIndex = $index) {
            <fieldset class="work-time-controlling-card small-abstand-bottom">
                <legend class="sr-only">
                    {{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ABSENCE_LEGEND', {index: absenceIndex + 1}) }}
                </legend>
                @if (absence.editPermitted) {
                    <button dvlibButton="link-button"
                            class="close-button"
                            [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_ABSENCE')"
                            [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_ABSENCE')"
                            (click)="removeAbsence(absence)">
                        <i class="custom-icon custom-icon-close-thin"></i>
                    </button>
                }
                <div class="time-inputs small-abstand-bottom">
                    <input type="time"
                           [name]="'absence_von_' + absenceIndex"
                           [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ABSENCE_VON')"
                           [disabled]="!absence.editPermitted"
                           [(ngModel)]="absence.von"
                           validVon
                           [dateStart]="state().dateMoment()"
                           [dateEnd]="state().dateMoment()"
                           [timeEnd]="absence.bis()"
                           [required]="!!absence.bis()" class="form-control input-sm no-margin"/>
                    <input type="time"
                           [name]="'absence_bis_' + absenceIndex"
                           [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ABSENCE_BIS')"
                           [disabled]="!absence.editPermitted"
                           [(ngModel)]="absence.bis"
                           validBis
                           [timeStart]="absence.von()"
                           [dateStart]="state().dateMoment()"
                           [dateEnd]="state().dateMoment()"
                           [required]="!!absence.von()" class="form-control input-sm no-margin"/>
                </div>
                <div class="time-inputs small-abstand-bottom">
                    <p class="input-spacer">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.DURATION') }}</p>
                    <input type="text"
                           dvMinutesInput
                           [placeholder]="t('COMMON.HOURS_MINUTES_FORMAT')"
                           [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.DURATION')"
                           [name]="'duration_' + absenceIndex"
                           [disabled]="absence.duration().disabled"
                           [(ngModel)]="absence.duration().minutes"
                           [required]="absence.duration().required"
                           class="form-control input-sm no-margin"/>
                </div>
                <div class="row small-abstand-bottom">
                    <div class="col-md-12">
                        <select [name]="'absenceType_' + absenceIndex"
                                [attr.aria-label]="t('PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE')"
                                [disabled]="!absence.editPermitted"
                                class="form-control no-margin input-sm"
                                [(ngModel)]="absence.absenceTypeId" required
                                [tooltip]="t('PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE')">
                            <option [ngValue]="undefined" disabled>
                                {{ t('PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE') }}
                            </option>
                            @for (type of absenceTypes(); track type.id) {
                                <option [ngValue]="type.id">{{ type.name }}</option>
                            }
                        </select>
                    </div>
                </div>
                <button dvlibButton="link-button"
                        class="standort-selection"
                        [disabled]="!absence.editPermitted"
                        dvOverlay
                        [overlayTemplate]="selectStandortForAbsence"
                        [overlayContext]="{$implicit: absence}"
                        [overlayOpen]="absence.overlayOpen()"
                        (closeOverlay)="absence.overlayOpen.set(false)"
                        (click)="absence.overlayOpen.set(true)">
                        <span class="button-content">
                            @if (absence.kinderOrt) {
                                {{ absence.kinderOrt.text }}
                            } @else {
                                + {{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.SELECT_LOCATION') }}
                            }
                        </span>
                </button>
            </fieldset>
        }
        <div class="row" *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
            <div class="col-md-12 text-center">
                <button dvlibButton="link-button"
                        [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_ABSENCE')"
                        [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_ABSENCE')"
                        (click)="addAbsence()">
                    <i class="custom-icon custom-icon-plus-thin"></i>
                </button>
            </div>
        </div>
    </td>
    <td>
        @if (state().isBalanceCorrectionVisible()) {
            <div class="work-time-controlling-card">
                <button *dvlibRequirePermission="'work_time_controlling:balance_correction:general'"
                        dvlibButton="link-button"
                        class="close-button"
                        [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_CORRECTION')"
                        [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_CORRECTION')"
                        (click)="removeCorrection()">
                    <i class="custom-icon custom-icon-close-thin"></i>
                </button>
                <div class="correction">
                    <span class="floating-text">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.CORRECTION') }}</span>
                    <input type="text"
                           class="form-control input-sm no-margin"
                           dvMinutesInput
                           [placeholder]="t('COMMON.HOURS_MINUTES_FORMAT')"
                           required
                           [name]="'balance_correction'"
                           [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION')"
                           [dvlibRequirePermission]="'work_time_controlling:balance_correction:general'"
                           [(ngModel)]="state().balanceCorrection"
                           [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION')"/>
                </div>
            </div>
        } @else {
            <button *dvlibRequirePermission="'work_time_controlling:balance_correction:general'"
                    class="btn btn-link add-correction"
                    (click)="state().isBalanceCorrectionVisible.set(true)">
                + {{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION') }}
            </button>
        }
    </td>
    <td>
        @if (state().isBalanceCorrectionHolidaysVisible()) {
            <div class="work-time-controlling-card">
                <button *dvlibRequirePermission="'work_time_controlling:balance_correction:general'"
                        dvlibButton="link-button"
                        class="close-button"
                        [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_CORRECTION')"
                        [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_CORRECTION')"
                        (click)="removeCorrectionHolidays()">
                    <i class="custom-icon custom-icon-close-thin"></i>
                </button>
                <div class="correction">
                    <span class="floating-text">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.CORRECTION') }}</span>
                    <input type="text"
                           class="form-control input-sm no-margin"
                           dvMinutesInput
                           [placeholder]="t('COMMON.HOURS_MINUTES_FORMAT')"
                           required
                           [name]="'balance_correction_holidays'"
                           [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION_HOLIDAYS')"
                           [dvlibRequirePermission]="'work_time_controlling:balance_correction:general'"
                           [(ngModel)]="state().balanceCorrectionHolidays"
                           [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION_HOLIDAYS')"/>
                </div>
            </div>
        } @else {
            <button *dvlibRequirePermission="'work_time_controlling:balance_correction:general'"
                    class="btn btn-link add-correction"
                    (click)="state().isBalanceCorrectionHolidaysVisible.set(true)">
                + {{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION_HOLIDAYS') }}
            </button>
        }
    </td>

    <ng-template #selectStandortForAbsence let-model="$implicit">
        <div class="overlay-selection">
            <dv-search-entity [entityToSearch]="'KITA'"
                              [name]="'absence-kita-' + model.uiId"
                              [(ngModel)]="model.kinderOrt"
                              [placeholder]="t('COMMON.KINDERORT.AUSWAEHLEN')">
            </dv-search-entity>
        </div>
    </ng-template>

    <ng-template #selectStandortForTime let-model="$implicit">
        <div class="overlay-selection">
            <dv-search-entity [entityToSearch]="'KITA'"
                              [name]="'time-kita-' + model.uiId"
                              [(ngModel)]="model.kinderOrt"
                              [placeholder]="t('COMMON.KINDERORT.AUSWAEHLEN')"
                              class="smaller-abstand-bottom">
            </dv-search-entity>
            <dv-search-entity [entityToSearch]="'GRUPPE'"
                              [name]="'time-gruppe-' + model.uiId"
                              [(ngModel)]="model.kinderOrtFraktion"
                              [placeholder]="t('COMMON.GRUPPE.AUSWAEHLEN')">
            </dv-search-entity>
        </div>
    </ng-template>
</ng-container>
