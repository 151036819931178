/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {BlobInfo} from '@dv/kitadmin/models';
import type {EmailVersandStatus, ILimited, IPersisted} from '@dv/shared/code';
import {checkPresent, DvbRestUtil} from '@dv/shared/code';
import type {ITimelineEntry} from '@dv/shared/timeline';
import type moment from 'moment';
import type {KontaktKorrespondenzType} from './KontaktKorrespondenzType';

export class KontaktKorrespondenz implements ITimelineEntry, IPersisted, ILimited {

    public constructor(
        public id: string,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public timestampErstellt: moment.Moment,
        public timestampMutiert: moment.Moment,
        public userErstellt: string,
        public userMutiert: string,
        public type: KontaktKorrespondenzType,
        public kontaktpersonId: string,
        public subject: string,
        public blobInfos: BlobInfo[] = [],
        public content: string | null = null,
        public emailVersandStatus: EmailVersandStatus | null = null,
        public empfaengerAdresse: string | null = null,
        public emailContentPlainText: string | null = null,
        public emailContentHTML: string | null = null,
        public replyToAdresse: string | null = null,
        public replyToName: string | null = null,
        public rechnungsId: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): KontaktKorrespondenz {
        const blobInfos =
            data.blobInfos ? data.blobInfos.map((blobInfo: any) => BlobInfo.apiResponseTransformer(blobInfo)) : [];

        return new KontaktKorrespondenz(
            data.id,
            DvbRestUtil.localDateTimeToMoment(data.gueltigAb),
            DvbRestUtil.localDateTimeToMoment(data.gueltigBis),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestampErstellt)),
            checkPresent(DvbRestUtil.localDateTimeToMoment(data.timestampMutiert)),
            data.userErstellt,
            data.userMutiert,
            data.type,
            data.kontaktpersonId,
            data.subject,
            blobInfos,
            data.content,
            data.emailVersandStatus,
            data.empfaengerAdresse,
            data.emailContentPlainText,
            data.emailContentHTML,
            data.replyToAdresse,
            data.replyToName,
            data.rechnungsId,
        );
    }
}
