<form name="rechnungsKonfigurationBearbeitenForm" (ngSubmit)="submit(null)" novalidate *transloco="let t">
    <div class="modal-body">
        <h1>
            {{ t('KINDERORT.KONFIGURATION_EDIT_WARNUNG_COUNT', {anzahl: stats.totalRechnungenAffected}) }}
        </h1>

        @if (stats.excludedRechnungen > 0) {
            <h3 [innerHtml]="t('KINDERORT.KONFIGURATION_EDIT_EXCLUDED_RECHNUNGEN', {anzahl: stats.excludedRechnungen})"></h3>
        }

        <p>{{ t('KINDERORT.KONFIGURATION_EDIT_WARNUNG') }}</p>
    </div>
    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            <dvlib-loading-button [type]="'submit'" [isLoading]="isLoading$ | async">
                <span>{{ t('COMMON.FORTFAHREN') }}</span>
            </dvlib-loading-button>
            <button type="button"
                    class="btn btn-link"
                    (click)="hide()">
                {{ t('COMMON.ABBRECHEN') }}
            </button>
        </dvlib-button-list>
    </div>
</form>
