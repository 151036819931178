/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {HolidayCreditError} from '@dv/shared/backend/model/holiday-credit-error';
import {Translator} from '@dv/shared/translator';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'dv-holiday-credit-error',
    standalone: true,
    imports: [
        TooltipModule,
    ],
    templateUrl: './holiday-credit-error.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HolidayCreditErrorComponent {
    public errors = input.required<HolidayCreditError[]>();

    private translator = inject(Translator);
    public errorTitle = this.translator.instant('WORK_TIME_CONTROLLING.HOLIDAYS.ERROR_TITLE');

    public errorText = computed(() => {
        const sortedErrors = [...this.errors()].sort((a, b) => a.localeCompare(b));

        const errors = sortedErrors
            .map(err => this.translator.instant(`WORK_TIME_CONTROLLING.HOLIDAYS.HOLIDAY_CREDIT_ERROR.${err}`));

        return `${this.errorTitle}\n${errors.join('\n')}`;
    });
}
