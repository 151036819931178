<dvlib-date-switcher
    mode="month"
    [date]="store.selectedMonth()"
    (debouncedDateChange)="store.applyMonth($event)"
    (debounceStarted)="store.startLoading()"
    class="normal-abstand-bottom">
    <dvlib-spinner class="hidden-print small-abstand-left" [show]="store.isLoading()" fillSpace="true"/>
</dvlib-date-switcher>

<dv-work-time-controlling-table
    [data]="store.sortedData()"
    [sortBy]="store.sortBy()"
    [sortOrder]="store.sortOrder()"
    [kinderOrteEntities]="store.kinderOrteEntityMap()"
    [kinderOrtFraktionenEntities]="store.kinderOrtFraktionenEntityMap()"
    [absenceTypes]="store.absenceTypes()"
    (toggleSort)="store.toggleSort($event)"
    (toggleAngestellte)="store.toggleAngestellte($event)"
    (toggleDay)="store.toggleDay($event.angestellteId, $event.date)"
    (confirmEdit)="confirmEdit($event)"
    (addTempDay)="store.addTempDay($event)"
    (removeTempDay)="store.removeTempDay($event)"
    (confirmTempDay)="confirmTempDay($event)">
</dv-work-time-controlling-table>
