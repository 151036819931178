<div class="dvb-maintenance-config">
    <div>
        <h2 data-translate="ADMINISTRATION.APP_CONFIG.HEADER"></h2>
    </div>

    <form ng-submit="vm.submit()" novalidate>

        <table class="table table-striped">
            <caption class="sr-only" data-translate="ADMINISTRATION.APP_CONFIG.TABLE.CAPTION"></caption>
            <thead class="sr-only">
            <tr>
                <th data-translate="ADMINISTRATION.APP_CONFIG.TABLE.KEY"></th>
                <th data-translate="ADMINISTRATION.APP_CONFIG.TABLE.VALUE"></th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="entry in vm.entries">
                <td ng-bind="entry.key"></td>
                <td>
                    <input type="text"
                           uib-tooltip="{{entry.key}}"
                           tooltip-placement="top-left"
                           class="form-control no-margin"
                           ng-model="entry.value">
                </td>
            </tr>
            </tbody>
        </table>

        <dvb-submit-cancel-buttons is-loading="vm.isLoading"
                                   on-cancel="vm.reset()"
                                   cancel-label="COMMON.RESET_FILTER">
        </dvb-submit-cancel-buttons>

    </form>
</div>
