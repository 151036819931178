/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import {toObservable} from '@angular/core/rxjs-interop';
import type {NgForm, ValidatorFn} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {KinderOrtTransformer, RRuleBisType, RRuleFormModel, RRuleUtil, Termin} from '@dv/kitadmin/models';
import {SearchListComponent} from '@dv/kitadmin/search';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {
    BackendLimitedWithOptionalTimeComponent,
    DatepickerTextfieldComponent,
    ElasticTextInputDirective,
    FormGroupSpec,
    handleResponseError,
    LoadingState,
    MaxDateDirective,
    MinDateDirective,
    SetValueDirective,
    SpinnerComponent,
    ValidatorDirective,
    ValidBisDirective,
    ValidVonDirective,
} from '@dv/shared/angular';
import {AngestellteService} from '@dv/shared/backend/api/angestellte.service';
import {KinderOrteService} from '@dv/shared/backend/api/kinder-orte.service';
import {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import {
    COLOR_PALETTE,
    DB_MAX_LENGTH,
    DB_SHORT_LENGTH,
    DvbRestUtil,
    DvbUtil,
    Gueltigkeit,
    IPersistable,
    isNullish,
    isPresent,
    nameComparator,
    SearchResultEntry,
    TEXT_COLOR_PALETTE,
    UndefinedToPartial,
} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import moment from 'moment';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgxColorsModule, validColorValidator} from 'ngx-colors';
import {Frequency} from 'rrule';
import {catchError, combineLatest, defaultIfEmpty, map, Observable, switchMap} from 'rxjs';
import {defaultTerminColor} from '../../../kinderort/component/personal/personalplanung/personalplanung-termine.store';
import {Angestellte} from '../../anstellung/models/Angestellte';
import type {RRuleForm, TerminFormModel} from './termin-form-model';
import {terminFormSelectionValidator} from './termin-form-selection.validator';

function sorted(value: JaxTerminType[]): JaxTerminType[] {
    return [...value].sort(nameComparator);
}

@Component({
    selector: 'dv-termin-form',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        SubmitCancelButtonsComponent,
        TranslocoModule,
        TooltipModule,
        ElasticTextInputDirective,
        SearchListComponent,
        NgxColorsModule,
        DatepickerTextfieldComponent,
        MinDateDirective,
        MaxDateDirective,
        ValidatorDirective,
        ValidVonDirective,
        ValidBisDirective,
        SetValueDirective,
        SpinnerComponent,
        BackendLimitedWithOptionalTimeComponent,
    ],
    templateUrl: './termin-form.component.html',
    styleUrl: './termin-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminFormComponent implements AfterViewInit {

    @Input({required: true, transform: sorted})
    public terminTypes: JaxTerminType[] = [];

    @Input({required: true})
    public set termin(termin: Termin) {
        this._termin.set(termin);

        this.model = {
            id: termin.id,
            title: termin.title ?? '',
            gueltigAb: termin.gueltigAb ?? undefined,
            gueltigBis: termin.gueltigBis ?? undefined,
            von: DvbRestUtil.momentTolocaleHHMMTime(termin.von) ?? undefined,
            bis: DvbRestUtil.momentTolocaleHHMMTime(termin.bis) ?? undefined,
            wiederkehrend: termin?.wiederkehrend ?? false,
            alleAngestellte: termin.alleAngestellte,
            alleKinderOrte: termin.alleKinderOrte,
            titleHidden: termin.titleHidden,
            selectedAngestellte: [],
            selectedExcludedAngestellte: [],
            selectedKinderOrte: [],
            bemerkung: termin.bemerkung ?? '',
            backgroundColor: termin.backgroundColor ?? defaultTerminColor,
            textColor: termin.textColor ?? '#FFFFFF',
            terminTypeId: termin.terminType?.id,
        };

        this.rruleModel = getRruleModel(termin);
        this.excludedDatesModel = termin.excludedDateRanges.slice();
    }

    @Output() public readonly save: EventEmitter<{ context: LoadingState; termin: Termin }> = new EventEmitter();
    @Output() public readonly cancel: EventEmitter<void> = new EventEmitter();

    @ViewChild('titleInput', {static: false}) private titleInput: ElementRef<HTMLInputElement> | undefined;

    public readonly loadingState = new LoadingState();
    public readonly frequenzyTypes = Frequency;
    public readonly bisTypes = RRuleBisType;
    public readonly colorPalette = COLOR_PALETTE;
    public readonly textColorPalette = TEXT_COLOR_PALETTE;

    public model: Partial<TerminFormModel & IPersistable> = {};
    public rruleModel: UndefinedToPartial<RRuleForm> = new RRuleFormModel();
    public excludedDatesModel: Gueltigkeit[] = [];
    public exclusionPickerModel: Gueltigkeit = new Gueltigkeit();

    public readonly selectionValidator: ValidatorFn = terminFormSelectionValidator;
    public readonly colorValidator: ValidatorFn = validColorValidator();

    public readonly DB_SHORT_LENGTH = DB_SHORT_LENGTH;
    public readonly DB_MAX_LENGTH = DB_MAX_LENGTH;
    public readonly MAX_REPETITIONS = RRuleFormModel.MAX_REPETITIONS;

    private _termin: WritableSignal<Termin | undefined> = signal(undefined);

    public selectedAngestellte$: Observable<SearchResultEntry[]> = toObservable(this._termin).pipe(
        map(t => t?.angestellteIds ?? []),
        map(ids => ids.map(id => this.angestellteService.get$({angestellteId: id, angestellteIdMatrix: {}}).pipe(
            map(Angestellte.apiResponseTransformer),
            map(a => a.toSearchResultEntry()),
            catchError(error => {
                handleResponseError(error);

                return [];
            }),
        ))),
        switchMap(searchResultEntries => combineLatest(searchResultEntries).pipe(defaultIfEmpty([]))),
    );

    public selectedExcludedAngestellte$: Observable<SearchResultEntry[]> = toObservable(this._termin).pipe(
        map(t => t?.excludedAngestellteIds ?? []),
        map(ids => ids.map(id => this.angestellteService.get$({angestellteId: id, angestellteIdMatrix: {}}).pipe(
            map(Angestellte.apiResponseTransformer),
            map(a => a.toSearchResultEntry()),
            catchError(error => {
                handleResponseError(error);

                return [];
            }),
        ))),
        switchMap(searchResultEntries => combineLatest(searchResultEntries).pipe(defaultIfEmpty([]))),
    );

    public selectedKinderOrte$: Observable<SearchResultEntry[]> = toObservable(this._termin).pipe(
        map(t => t?.kinderOrtIds ?? []),
        map(ids => ids.map(id => this.kinderOrteService.getKinderOrt$({kinderOrtId: id, kinderOrtIdMatrix: {}})
            .pipe(
                map(k => KinderOrtTransformer.create().apiResponseTransformer(k)),
                map(k => k.toSearchResultEntry()),
                catchError(error => {
                    handleResponseError(error);

                    return [];
                }),
            ))),
        switchMap(searchResultEntries => combineLatest(searchResultEntries).pipe(defaultIfEmpty([]))),
    );

    public constructor(
        private errorService: ErrorService,
        private angestellteService: AngestellteService,
        private kinderOrteService: KinderOrteService,
    ) {
    }

    public ngAfterViewInit(): void {
        if (this.titleInput?.nativeElement && DvbUtil.isEmptyString(this._termin()?.title)) {
            this.titleInput.nativeElement.focus();
        }
    }

    public showAngestellteSearch = (): boolean => {
        return !this.model.alleAngestellte;
    };

    public showKinderOrtSearch = (): boolean => {
        return !this.model.alleKinderOrte;
    };

    public submitForm(ngForm: NgForm): void {
        const formGroup: FormGroupSpec<TerminFormModel> = ngForm.form;
        let formValid = formGroup.valid || false;
        this.errorService.handleValidationError(formValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        const {gueltigAb, gueltigBis, rrule} = formGroup.controls;
        this.errorService.handleControlError([gueltigAb, gueltigBis], 'ERRORS.ERR_INVALID_DATES');

        this.errorService.handleValidationError(isNullish(formGroup.errors?.selection),
            'ERRORS.ERR_INVALID_KINDERORT_ANGESTELLTE');

        if (rrule) {
            this.errorService.handleControlError(rrule.controls?.repetitions,
                'ERRORS.ERR_INVALID_REPEAT_COUNT');
            this.errorService.handleControlError(rrule.controls?.endDateLocal,
                'PERSONAL.TERMIN.INVALID_WIEDERHOLUNG_END');

            const exclusionError = this.addExcludedDateInternal(false);
            const noExclusionError = isNullish(exclusionError);
            formValid &&= noExclusionError;
            this.errorService.handleValidationError(noExclusionError, exclusionError!);
        }

        if (!formValid) {
            return;
        }

        const termin = this.formToTermin(formGroup.value);
        const terminValid = termin.isValid();
        this.errorService.handleValidationError(terminValid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (terminValid) {
            this.save.emit({context: this.loadingState, termin});
        }
    }

    public getFrequencyStr(freq: Frequency): string {
        return Frequency[freq];
    }

    public addExcludedDate(): void {
        const error = this.addExcludedDateInternal(true);
        if (isPresent(error)) {
            this.errorService.handleValidationError(false, error);
        }
    }

    private addExcludedDateInternal(errorIfEmpty: boolean = true): string | null {
        const gueltigkeit = this.exclusionPickerModel;
        if (gueltigkeit.gueltigBis === null) {
            gueltigkeit.gueltigBis = gueltigkeit.gueltigAb;
        }

        if (!errorIfEmpty && isNullish(gueltigkeit.gueltigAb) && isNullish(gueltigkeit.gueltigBis)) {
            return null;
        }

        if (!gueltigkeit.isValid()) {

            return 'ERRORS.ERR_EXCLUSION_INVALID';
        }

        if (this.excludedDatesModel.some(g => g.equals(gueltigkeit))) {
            this.errorService.handleValidationError(false, 'ERRORS.ERR_DUPLICATE_EXLUSION_RANGE');

            return 'ERRORS.ERR_DUPLICATE_EXLUSION_RANGE';
        }

        this.excludedDatesModel.push(gueltigkeit);
        this.exclusionPickerModel = new Gueltigkeit();

        return null;
    }

    public removeExcludedDate(index: number): void {
        this.excludedDatesModel.splice(index, 1);
    }

    private formToTermin(value: Partial<Omit<TerminFormModel, 'rrule'>>): Termin {
        const rruleModel = this.formToRruleModel();

        return new Termin(
            this.model.id,
            (value.selectedAngestellte ?? []).map(DvbUtil.mapToIdChecked),
            (value.selectedExcludedAngestellte ?? []).map(DvbUtil.mapToIdChecked),
            (value.selectedKinderOrte ?? []).map(DvbUtil.mapToIdChecked),
            value.gueltigAb,
            value.gueltigBis ?? moment(value.gueltigAb).startOf('day'),
            DvbRestUtil.localeHHMMTimeToMoment(value.von),
            DvbRestUtil.localeHHMMTimeToMoment(value.bis),
            value.title,
            value.bemerkung,
            value.backgroundColor,
            value.textColor,
            value.alleKinderOrte,
            value.alleAngestellte,
            value.titleHidden,
            value.wiederkehrend,
            rruleModel ? RRuleUtil.calculateEndDateLocalTime(rruleModel) : null,
            rruleModel ? RRuleUtil.toRRuleString(rruleModel) : null,
            null,
            this.excludedDatesModel,
            this.terminTypes.find(t => t.id === value.terminTypeId),
        );
    }

    private formToRruleModel(): RRuleFormModel | undefined {
        if (this.model.wiederkehrend !== true) {
            return undefined;
        }
        const value = this.rruleModel;

        return new RRuleFormModel(
            value.frequenzTyp,
            value.interval,
            value.bisType,
            moment(this.model.gueltigAb).utc(true),
            undefined,
            value.repetitions,
            value.endDateLocal,
        );
    }

    public onAlleAngestellteChange(alleAngestellte: undefined | boolean): void {
        if (alleAngestellte && this.model.alleKinderOrte) {
            this.model.alleKinderOrte = false;
        }
    }

    public onAlleKinderOrteChange(alleKinderOrte: undefined | boolean): void {
        if (alleKinderOrte && this.model.alleAngestellte) {
            this.model.alleAngestellte = false;
        }
    }
}

function getRruleModel(termin?: Termin): RRuleFormModel {
    return termin?.wiederkehrend && termin.rruleStr ?
        RRuleUtil.initModel(termin.rruleStr, termin.recurrenceEnd ?? undefined) :
        new RRuleFormModel();
}
