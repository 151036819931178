/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {withDevtools} from '@angular-architects/ngrx-toolkit';
import {inject} from '@angular/core';
import {handleResponse} from '@dv/shared/angular';
import {WorkTimeControllingService} from '@dv/shared/backend/api/work-time-controlling.service';
import type {JaxWorkTimeControlling} from '@dv/shared/backend/model/jax-work-time-controlling';
import {DvbRestUtil} from '@dv/shared/code';
import {patchState, signalStore, withHooks, withMethods} from '@ngrx/signals';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import type moment from 'moment';
import {pipe, switchMap, tap} from 'rxjs';
import {withWorkTimeControllingTable} from '../store/work-time-controlling-table.store';

export const MandantWorkTimeControllingStore = signalStore(
    withDevtools('workTimeControlling'),
    withWorkTimeControllingTable(),
    withMethods((
        store,
        service: WorkTimeControllingService = inject(WorkTimeControllingService),
    ) => ({
        _load: rxMethod<moment.Moment>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            switchMap(selectedMonth => service.getAll$({
                    workTimeControlling: {
                        stichtag: DvbRestUtil.momentToLocalDateChecked(selectedMonth),
                    },
                }).pipe(
                    handleResponse({
                        next: (data: JaxWorkTimeControlling) => store._initTableData(data),
                        finalize: () => patchState(store, {isLoading: false}),
                    }),
                ),
            ),
        )),
    })),
    withHooks({
        onInit(store) {
            store._load(store.selectedMonth);
        },
    }),
);
