<div class="dvb-kind-tarife-parameter">
    <div ng-repeat="entry in (sortedEntries = (vm.kindParameter.entries | toArray:false | orderBy:'gueltigAb':true))">
        <dvb-collapsible-panel
            ng-if="sortedEntries[$index-1].gueltigAb.diff(entry.gueltigBis, 'days') > 1 || $first && vm.isEntryBeendet(entry)"
            is-first="$first"
            is-collapsed="true"
            dvb-auth
            require-permission="kita:manage:general"
            on-unauthorised-disable>
            <panel-header>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{entry.gueltigBis | amDateFormat: 'D.M.Y'}}"></p>
            </panel-header>
            <panel-content>
                <p data-translate="COMMON.BEENDET_TITLE"
                   data-translate-value-endedatum="{{entry.gueltigBis | amDateFormat: 'D.M.Y'}}"></p>

                <p class="normal-abstand-top">
                    <a href=""
                       data-translate="COMMON.BEENDEN_REVERT"
                       ng-click="vm.onRevert({tarifParameterHistoryEntry: entry})">
                    </a>
                </p>
            </panel-content>
        </dvb-collapsible-panel>

        <dvb-collapsible-panel limited-entity="entry"
                               is-first="$first && !vm.isEntryBeendet(entry)"
                               expand-ab="vm.today"
                               expand-bis="vm.today">
            <panel-header>
                <dvb-kind-tarife-parameter-header tarif-parameter-history-entry="entry">
                </dvb-kind-tarife-parameter-header>
            </panel-header>
            <panel-content>
                <dvb-kind-tarife-parameter-content
                    on-duplicate="vm.onDuplicate({tarifParameterHistoryEntry: tarifParameterHistoryEntry})"
                    on-update="vm.onUpdate({tarifParameterHistoryEntry: tarifParameterHistoryEntry})"
                    on-delete="vm.onDelete({tarifParameterHistoryEntry: tarifParameterHistoryEntry})"
                    tarif-parameter-history-entry="entry"
                    tarif-parameter="vm.tarifParameter"
                    relevant-parameter-ids="vm.kindParameter.relevantParameterIds">
                </dvb-kind-tarife-parameter-content>
            </panel-content>
        </dvb-collapsible-panel>
    </div>
</div>
