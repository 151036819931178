/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AngestellteService} from '@dv/shared/backend/api/angestellte.service';
import {isPresent} from '@dv/shared/code';
import {TimelineDetailComponent, TimelineIconComponent} from '@dv/shared/timeline';
import {Translator} from '@dv/shared/translator';
import {TranslocoModule} from '@jsverse/transloco';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {UiScrollModule} from 'ngx-ui-scroll';
import {Angestellte} from '../../models/Angestellte';
import {AngestellteKorrespondenzScrollDataSource} from '../../service/AngestellteKorrespondenzScrollDataSource';
import {
    AngestellteKorrespondenzTimelineDetailsComponent,
} from '../angestellte-korrespondenz-timeline-details/angestellte-korrespondenz-timeline-details.component';
import {AngestellteKorrespondenzTimelineService} from './AngestellteKorrespondenzTimelineService';

@Component({
    selector: 'dv-angestellte-korrespondenz',
    standalone: true,
    imports: [
        TranslocoModule,
        ButtonsModule,
        TimelineIconComponent,
        TimelineDetailComponent,
        UiScrollModule,
        AngestellteKorrespondenzTimelineDetailsComponent,
    ],
    templateUrl: './angestellte-korrespondenz.component.html',
    styles: ':host {display: block;}',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AngestellteKorrespondenzComponent implements OnInit {

    @Input() public angestellte?: Angestellte;

    public timelineService: AngestellteKorrespondenzTimelineService;
    public dataSource?: AngestellteKorrespondenzScrollDataSource;

    public constructor(
        translateService: Translator,
        private readonly angestellteService: AngestellteService,
    ) {
        this.timelineService = new AngestellteKorrespondenzTimelineService(translateService, angestellteService);
    }

    public ngOnInit(): void {
        if (isPresent(this.angestellte)) {
            this.dataSource = new AngestellteKorrespondenzScrollDataSource(this.angestellteService, this.angestellte);
        }
    }
}
