/*
 * Copyright © 2025 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxAngestellteStandortWorkDay} from '@dv/shared/backend/model/jax-angestellte-standort-work-day';
import type {JaxPlannedAbsenceTermin} from '@dv/shared/backend/model/jax-planned-absence-termin';
import type {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import type {Displayable} from '@dv/shared/code';
import {nextId, stringUnion, TimeRangeUtil} from '@dv/shared/code';
import type {AngestellteWorkDay} from '../work-time-controlling-table/work-time-controlling-table.models';

export const WORK_TIME_PLANNED_TIME_TYPE = stringUnion('WORK', 'TERMIN');

export type WorkTimePlannedTimeType = typeof WORK_TIME_PLANNED_TIME_TYPE.type;

export type WorkTimePlannedTime = {
    uiId: string;
    name: string;
    type: WorkTimePlannedTimeType;
    timeRange: Readonly<JaxTimeRange>;
};

export function toPlannedTimes(
    dailyHours: AngestellteWorkDay,
    {kinderOrteById, fraktionenById}: {
        kinderOrteById: { [kinderOrtId: EntityId]: Displayable };
        fraktionenById: { [kinderOrtFraktionId: EntityId]: Displayable };
    },
): WorkTimePlannedTime[] {
    const plannedTimeRanges: WorkTimePlannedTime[] = dailyHours.standortWorkDays
        .reduce((acc: WorkTimePlannedTime[], workDay: JaxAngestellteStandortWorkDay) => [
            ...acc,
            ...workDay.plannedTimeRanges?.map(timeRange => {
                    const kinderOrtName = workDay.kinderOrtId ?
                        kinderOrteById[workDay.kinderOrtId]?.getDisplayName() :
                        '';
                    const kinderOrtFraktionName = workDay.kinderOrtFraktionId ?
                        fraktionenById[workDay.kinderOrtFraktionId]?.getDisplayName() :
                        '';

                    return {
                        uiId: nextId(),
                        name: (kinderOrtName || kinderOrtFraktionName) ?? '',
                        type: 'WORK',
                        timeRange,
                    } satisfies WorkTimePlannedTime;
                },
            ) ?? [],
        ], []);

    const plannedAbsenceTermine: WorkTimePlannedTime[] = dailyHours.absenceTermine
        .map((absenceTermin: JaxPlannedAbsenceTermin) => ({
            uiId: nextId(),
            name: absenceTermin.title ?? '',
            type: 'TERMIN',
            timeRange: absenceTermin.timeRange,
        }));

    return [...plannedTimeRanges, ...plannedAbsenceTermine].sort((a, b) => {
        return TimeRangeUtil.BACKEND_TIME_RANGE_COMPARATOR(a.timeRange, b.timeRange);
    });
}
