<div class="dvb-kind-tarife-parameter-content">

    <div ng-if="!vm.editMode">

        <div class="history-columns">
            <p class="history-column-left">
                <dvb-gueltigkeit entity="vm.tarifParameterHistoryEntry"></dvb-gueltigkeit>
            </p>

            <div class="history-column-middle">
                <div ng-repeat="value in vm.tarifParameterHistoryEntry.values | orderBy:'parameter.orderValue'">
                    <div class="row">
                        <div class="col-xs-12">
                            <span data-translate="KIND.LEISTUNGEN.KIND_PARAMETER_DISPLAY"
                                  data-translate-value-name="{{value.parameter.name}}"
                                  data-translate-value-value="{{value.value}}"></span>
                            <i class="fa fa-download"
                               ng-if="value.parameter.kibonParam"
                               uib-tooltip="{{'KIND.IMPORTED_PARAMETER' | translate}}"
                               tooltip-placement="top-left">
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul class="list-inline normal-abstand-top" dvb-auth require-permission="kita:manage:general">
            <li>
                <button class="btn btn-link"
                        ng-click="vm.editMode = true"
                        data-translate="COMMON.BEARBEITEN">
                </button>
            </li>
            <li>
                <button class="btn btn-link"
                        ng-click="vm.onDelete({tarifParameterHistoryEntry: vm.tarifParameterHistoryEntry})"
                        data-translate="COMMON.LOESCHEN">
                </button>
            </li>
            <li>
                <button class="btn btn-link"
                        ng-click="vm.onDuplicate({tarifParameterHistoryEntry: vm.tarifParameterHistoryEntry})"
                        uib-tooltip="{{'KIND.NEUE_PARAMETER_ERFASSEN_BASIEREND_AUF_PARAMETER' | translate}}"
                        data-translate="COMMON.DUPLIZIEREN">
                </button>
            </li>
        </ul>

    </div>

    <div ng-if="vm.editMode">
        <dvb-kind-tarife-parameter-form tarif-parameter="vm.tarifParameter"
                                        original-entry="vm.tarifParameterHistoryEntry"
                                        relevant-parameter="vm.relevantParameterIds"
                                        on-update="vm.update(tarifParameterHistoryEntry)"
                                        on-cancel="vm.editMode = false">
            <title-column>
                <dvb-gueltigkeit entity="vm.tarifParameterHistoryEntry"></dvb-gueltigkeit>
            </title-column>
        </dvb-kind-tarife-parameter-form>
    </div>
</div>
