/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import angular from 'angular';
import moment from 'moment';
import type {MaintenanceService} from '../../service/maintenanceService';

const componentConfig: angular.IComponentOptions = {
    transclude: false,
    bindings: {},
    template: require('./dvb-maintenance-actions.html'),
    controllerAs: 'vm',
};

export class DvbMaintenanceActions {

    public static $inject: readonly string[] = ['maintenanceService'];

    public isLoading: boolean = false;
    public lastResponse: unknown = null;

    public constructor(
        private readonly maintenanceService: MaintenanceService,
    ) {
    }

    public getAllBatchJobs(): void {
        this.handle(() => this.maintenanceService.getAllBatchJobs());
    }

    public purgeBatchJobs(): void {
        this.handle(() => this.maintenanceService.purgeBatchJobs());
    }

    public startLeistungsrechnung(): void {
        this.handle(() => this.maintenanceService.startLeistungsrechnung());
    }

    public cleanupSchulungsMandanten(): void {
        this.handle(() => this.maintenanceService.cleanupSchulungsMandanten());
    }

    public initDvbDemoData(): void {
        this.handle(() => this.maintenanceService.initDvbDemoData());
    }

    public initWtcDemoData(): void {
        this.handle(() => this.maintenanceService.initWtcDemoData());
    }

    public archiveKinder(): void {
        this.handle(() => this.maintenanceService.archiveKinder());
    }

    public unarchiveKinder(): void {
        this.handle(() => this.maintenanceService.unarchiveKinder());
    }

    public reindex(): void {
        this.handle(() => this.maintenanceService.reindex());
    }

    public clearControllingData(): void {
        // noinspection MagicNumberJS
        const year = 3000;
        this.handle(() => this.maintenanceService.clearAllControllingDataUpTo(year));
    }

    public recalculateCurrentControllingData(): void {
        this.handle(() => this.maintenanceService.recalculateControllingData(moment().year()));
    }

    public clearAuthCache(): void {
        this.handle(() => this.maintenanceService.clearAuthCache());
    }

    public createRechnungsUeberschussTasks(): void {
        this.handle(() => this.maintenanceService.createRechnungsUeberschussTasks());
    }

    public matchKibonExchangeData(): void {
        this.handle(() => this.maintenanceService.matchKibonExchangeData());
    }

    public shiroTestingBasics(): void {
        this.handle(() => this.maintenanceService.shiroTestingBasics());
    }

    public shiroTestingSuperAdmin(blocking: boolean): void {
        this.handle(() => this.maintenanceService.shiroTestingSuperAdmin(blocking));
    }

    public runAsMandant(): void {
        this.handle(() => this.maintenanceService.runAsMandant());
    }

    public cleanupBlobData(): void {
        this.handle(() => this.maintenanceService.cleanupBlobData());
    }

    public reloadKibonExchangeInstitutionen(): void {
        this.handle(() => this.maintenanceService.reloadKibonExchangeInstitutionen());
    }

    public fixRechnungenWithPdfs(): void {
        this.handle(() => this.maintenanceService.fixRechnungenWithPdfs());
    }

    private handle(func: () => angular.IHttpPromise<unknown>): void {
        this.isLoading = true;

        func()
            .then(response => {
                this.lastResponse = response.data;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}

componentConfig.controller = DvbMaintenanceActions;
angular.module('kitAdmin').component('dvbMaintenanceActions', componentConfig);
