<h2>{{ 'WORK_TIME_CONTROLLING.CONFIG.TITLE' | transloco }}</h2>
<div class="row">
    <div class="col-md-7">
        <form #form="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <dvlib-datepicker-textfield name="lockedUntil"
                                                [(ngModel)]="state().lockedUntil"
                                                [placeholder]="'WORK_TIME_CONTROLLING.CONFIG.LOCKED_UNTIL' | transloco"
                                                inputId="lockedUntil"
                                                (ngModelChange)="submitForm()">
                    </dvlib-datepicker-textfield>
                </div>
                <div class="col-md-6">
                    <dvlib-click-2-edit #monthLockedAfterDays
                                        [text]="state().monthLockedAfterDays() ? ('WORK_TIME_CONTROLLING.CONFIG.MONTH_LOCKED_AFTER_DAYS_COUNT' | transloco: {count: state().monthLockedAfterDays()}) : undefined"
                                        [tooltip]="'WORK_TIME_CONTROLLING.CONFIG.MONTH_LOCKED_AFTER_DAYS' | transloco"
                                        (save)="submitForm()">
                        <ng-template dvlibEditMode>
                            <input type="number"
                                   class="form-control"
                                   [dvlibClick2Edit]="monthLockedAfterDays"
                                   [attr.aria-label]="'WORK_TIME_CONTROLLING.CONFIG.MONTH_LOCKED_AFTER_DAYS' | transloco"
                                   name="bemerkung"
                                   [(ngModel)]="state().monthLockedAfterDays"/>
                        </ng-template>
                    </dvlib-click-2-edit>
                </div>
            </div>
        </form>
    </div>
</div>

