/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {computed, inject} from '@angular/core';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {AuthStore, handleResponse, handleResponseError} from '@dv/shared/angular';
import {
    WorkTimeControllingAbsenceTypesService,
} from '@dv/shared/backend/api/work-time-controlling-absence-types.service';
import type {
    WorkTimeControllingServiceGetDaysForAngestellteRequestParams,
    WorkTimeControllingServiceSaveManualEditRequestParams,
} from '@dv/shared/backend/api/work-time-controlling.service';
import {WorkTimeControllingService} from '@dv/shared/backend/api/work-time-controlling.service';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxWorkTimeControlling} from '@dv/shared/backend/model/jax-work-time-controlling';
import type {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import {checkPresent, Displayable, DvbDateUtil, DvbRestUtil, nameComparator} from '@dv/shared/code';
import {tapResponse} from '@ngrx/operators';
import {patchState, signalStoreFeature, type, withComputed, withHooks, withMethods, withState} from '@ngrx/signals';
import {setAllEntities, setEntity, updateEntity, withEntities} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import type moment from 'moment';
import {concatMap, map, pipe, switchMap, tap} from 'rxjs';
import type {
    SortBy,
    SortOrder,
    WorkDayUiState,
    WorkTimeControllingTableData,
} from '../work-time-controlling-table/work-time-controlling-table.models';
import {
    createForAngestellte,
    createWorkTimeControllingTableData,
    editPermittedFactory,
} from '../work-time-controlling-table/work-time-controlling-table.models';

type State = {
    isLoading: boolean;
    selectedMonth: moment.Moment;
    sortOrder: SortOrder;
    sortBy: SortBy;
    isLoadingAbsenceTypes: boolean;
    absenceTypes: JaxWorkTimeControllingAbsenceType[];
};

const initialState: State = {
    isLoading: false,
    selectedMonth: DvbDateUtil.startOfMonth(DvbDateUtil.today()),
    sortOrder: 'asc',
    sortBy: 'displayname',
    isLoadingAbsenceTypes: false,
    absenceTypes: [],
};

export type ConfirmEditParams = WorkTimeControllingServiceSaveManualEditRequestParams & {
    kinderOrtId?: EntityId;
};

type SaveEditParams = ConfirmEditParams & {
    removeTempDay?: boolean;
};

// suppress function return type warning, because we want the complex return type to be inferred
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,max-lines-per-function
export function withWorkTimeControllingTable() {

    return signalStoreFeature(
        withEntities<WorkTimeControllingTableData>(),
        withEntities({
            entity: type<Displayable>(),
            collection: 'kinderOrte',
        }),
        withEntities({
            entity: type<Displayable>(),
            collection: 'kinderOrtFraktionen',
        }),
        withState<State>(initialState),
        withComputed(
            store => ({
                datesInMonth: computed(() => DvbDateUtil.createDatesForMonth(store.selectedMonth())
                    .map(DvbRestUtil.momentToLocalDateChecked)),
                sortedData: computed<WorkTimeControllingTableData[]>(() => {
                    const entities = store.entities();
                    const sortBy = store.sortBy();
                    const sortOrder = store.sortOrder() === 'asc' ? 1 : -1;

                    return entities.slice().sort((a, b) => {
                        if (sortBy === 'displayname') {
                            return a.angestellteDisplayName.localeCompare(b.angestellteDisplayName) * sortOrder;
                        }

                        return (a[sortBy] - b[sortBy]) * sortOrder;
                    });
                }),
            }),
        ),
        withMethods((
            store,
            service = inject(WorkTimeControllingService),
            authStore = inject(AuthStore),
            absenceTypeService: WorkTimeControllingAbsenceTypesService = inject(WorkTimeControllingAbsenceTypesService),
        ) => ({
            startLoading() {
                patchState(store, {isLoading: true});
            },
            applyMonth(month: moment.Moment) {
                patchState(store, {selectedMonth: month});
            },
            toggleSort(sortBy: SortBy) {
                patchState(store, {
                    sortBy,
                    sortOrder: store.sortBy() === sortBy && store.sortOrder() === 'asc' ? 'desc' : 'asc',
                });
            },
            toggleAngestellte(angestellteId: string) {
                const current = store.entityMap()[angestellteId];
                patchState(store, updateEntity({id: angestellteId, changes: {expanded: !current.expanded}}));
            },
            _updateUiState(angestellteId: string, date: BackendLocalDate, newUiState: Partial<WorkDayUiState>) {
                patchState(store, updateEntity({
                    id: angestellteId,
                    changes: workDay => {
                        const currentUiState = workDay.workDaysUiState[date];

                        return {
                            ...workDay,
                            workDaysUiState: {...workDay.workDaysUiState, [date]: {...currentUiState, ...newUiState}},
                        };
                    },
                }));
            },
            toggleDay(angestellteId: string, date: BackendLocalDate) {
                this._updateUiState(angestellteId, date, {
                    expanded: !store.entityMap()[angestellteId].workDaysUiState[date].expanded,
                });
            },
            _updateDayLoading(angestellteId: string, date: BackendLocalDate, isLoading: boolean) {
                this._updateUiState(angestellteId, date, {isLoading});
            },
            _loadDaysForAngestellte: rxMethod<WorkTimeControllingServiceGetDaysForAngestellteRequestParams>(pipe(
                tap(() => patchState(store, {isLoading: true})),
                switchMap(payload => service.getDaysForAngestellte$(payload).pipe(
                    handleResponse({
                        next: data => {
                            const mergedData = createForAngestellte(data, {
                                isEditPermitted: editPermittedFactory(authStore),
                                frontendData: store.entityMap()[payload.angestellteId],
                                datesInMonth: store.datesInMonth(),
                                stichtag: checkPresent(payload.angestellteIdMatrix.stichtag),
                            });

                            patchState(store, setEntity(mergedData));
                        },
                        finalize: () => patchState(store, {isLoading: false}),
                    })),
                ),
            )),
            _initTableData(data: JaxWorkTimeControlling) {
                const wtcData = createWorkTimeControllingTableData(data.angestellteWorkDays, {
                    isEditPermitted: editPermittedFactory(authStore),
                    frontendData: store.entityMap(),
                    datesInMonth: store.datesInMonth(),
                });
                const kinderOrte: Displayable[] = data.kinderOrte
                    .map(kinderOrt => Displayable.apiResponseTransformer(kinderOrt));

                const kinderOrtFraktionen: Displayable[] = data.kinderOrtFraktionen
                    .map(fraktion => Displayable.apiResponseTransformer(fraktion));

                patchState(
                    store,
                    setAllEntities(wtcData),
                    setAllEntities(kinderOrte, {collection: 'kinderOrte'}),
                    setAllEntities(kinderOrtFraktionen, {collection: 'kinderOrtFraktionen'}));
            },
            addTempDay(angestellteId: string) {
                patchState(store, updateEntity({
                    id: angestellteId, changes: {
                        tempWorkDay: {
                            date: '',
                            note: '',
                            expectedMinutes: 0,
                            plannedMinutes: 0,
                            actualMinutes: 0,
                            absenceMinutes: 0,
                            timeBalance: 0,
                            holidayBalance: 0,
                            warning: undefined,
                            standortWorkDays: [],
                            absences: [],
                            absenceTermine: [],
                            balanceCorrection: null,
                            balanceCorrectionHolidays: null,
                        },
                        tempWorkDayUiState: {expanded: false, isLoading: false},
                    },
                }));
            },
            removeTempDay(angestellteId: string) {
                patchState(store, updateEntity({
                    id: angestellteId,
                    changes: {tempWorkDay: undefined, tempWorkDayUiState: undefined},
                }));
            },
            loadAbsenceTypes: rxMethod<void>(pipe(
                tap(() => patchState(store, {isLoadingAbsenceTypes: true})),
                switchMap(() => absenceTypeService.getAllTypes$().pipe(
                    map(data => data.types.sort(nameComparator)),
                    handleResponse({
                        next: absenceTypes => {
                            patchState(store, {absenceTypes});
                        },
                    })),
                )),
            ),
        })),
        withMethods((
            store,
            service: WorkTimeControllingService = inject(WorkTimeControllingService),
            errorService = inject(ErrorService),
        ) => ({
            _saveEdit: rxMethod<SaveEditParams>(pipe(
                tap(params => store._updateDayLoading(params.angestellteId, params.jaxAngestellteEditDay!.date, true)),
                concatMap(params => service.saveManualEdit$(params).pipe(
                    tap(() => {
                        if (params.removeTempDay) {
                            store.removeTempDay(params.angestellteId);
                        }
                    }),
                    tapResponse({
                        next: () => {
                            const date = checkPresent(params.jaxAngestellteEditDay).date;
                            const reloadParams: WorkTimeControllingServiceGetDaysForAngestellteRequestParams = {
                                angestellteId: params.angestellteId,
                                angestellteIdMatrix: {
                                    stichtag: date,
                                },
                                kinderOrtId: params.kinderOrtId,
                            };
                            store._loadDaysForAngestellte(reloadParams);
                        },
                        error: error => {
                            const moment = DvbDateUtil.toMoment(checkPresent(params.jaxAngestellteEditDay).date);
                            const date = checkPresent(moment).format(DvbDateUtil.DATE_FORMAT);
                            const angestellte = store.entityMap()[params.angestellteId]?.angestellteDisplayName;
                            if (angestellte) {
                                errorService.addValidationError('ERRORS.ERR_WTC_CHANGE_FOR_DAY', {angestellte, date});
                            }
                            handleResponseError(error, 'WorkTimeControlling: saveEdit');
                        },
                        finalize: () =>
                            store._updateDayLoading(params.angestellteId, params.jaxAngestellteEditDay!.date, false),
                    })),
                ),
            )),
        })),
        withMethods(store => ({
            confirmEdit(payload: ConfirmEditParams) {
                store._saveEdit({...payload});
            },
            confirmTempDay(payload: ConfirmEditParams) {
                store._saveEdit({...payload, removeTempDay: true});
            },
        })),
        withHooks({
            onInit(store) {
                store.loadAbsenceTypes();
            },
        }),
    );
}
