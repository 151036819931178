@if (blobInfos().length > 0) {
    <div class="btn-group" dropdown *transloco="let t">
        <div dropdownToggle>
            <dvlib-loading-button [asLink]="true"
                                  [spinnerRight]="true"
                                  [isLoading]="isLoading()"
                                  [tooltip]="t('COMMON.ANHAENGE_HERUNTERLADEN_MF', {count: blobInfos().length})"
                                  [label]="t('COMMON.ANHAENGE_HERUNTERLADEN_MF', {count: blobInfos().length})">
                <dvlib-icon iconName="dateien"/>&nbsp;<span class="caret"></span>
            </dvlib-loading-button>
        </div>
        <ul class="dropdown-menu" *dropdownMenu>
            @for (blobInfo of blobInfos(); track blobInfo.id; let last = $last) {
                <li [class.smaller-abstand-bottom]="!last">
                    <button dvlibButton="link-button"
                            (click)="downloadAttachment(blobInfo); $event.preventDefault();">
                        {{ blobInfo.filename }}
                    </button>
                </li>
            }
        </ul>
    </div>
}
