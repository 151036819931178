/*
 * Copyright © 2025 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Signal, WritableSignal} from '@angular/core';
import {computed, signal} from '@angular/core';
import type {BackendLocalTimeHHMM} from '@dv/shared/backend/model/backend-local-time-HHMM';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {Displayable} from '@dv/shared/code';
import {DvbDateUtil, DvbRestUtil, nextId, SearchResultEntry, TimeRangeUtil} from '@dv/shared/code';
import type {WorkTimeControllingAbsence} from '../work-time-controlling-table/work-time-controlling-table.models';

export type AbsenceFormModel = {
    uiId: string;
    editPermitted: boolean;
    overlayOpen: WritableSignal<boolean>;
    kinderOrt: SearchResultEntry | undefined;
    absenceTypeId: EntityId | undefined;
    von: WritableSignal<BackendLocalTimeHHMM | undefined>;
    bis: WritableSignal<BackendLocalTimeHHMM | undefined>;
    duration: Signal<{
        minutes: WritableSignal<number | undefined>;
        disabled: boolean;
        required: boolean;
    }>;
};

export function toAbsenceModels(
    absence: WorkTimeControllingAbsence[],
    {kinderOrteById}: { kinderOrteById: { [kinderOrtId: EntityId]: Displayable } },
): AbsenceFormModel[] {
    const sorted = [...absence].sort((a, b) => TimeRangeUtil.BACKEND_TIME_RANGE_COMPARATOR(a, b));

    return sorted.map((a): AbsenceFormModel => {
        const kinderOrt = a.kinderOrtId ?
            new SearchResultEntry('KITA', a.kinderOrtId, kinderOrteById[a.kinderOrtId].getDisplayName()) :
            undefined;

        return toAbsenceModel(kinderOrt, a);
    });
}

export function toAbsenceModel(
    kinderOrt: SearchResultEntry | undefined,
    a?: WorkTimeControllingAbsence,
): AbsenceFormModel {

    const editPermitted = a?.editPermitted ?? true;

    const von = signal(a?.von);
    const bis = signal(a?.bis);

    const duration = computed(() => {
        const v = von();
        const b = bis();

        const required = !v && !b;
        const editable = editPermitted && required;

        const minutes = v && b ?
            DvbDateUtil.getTimeDiff(
                DvbRestUtil.localeHHMMTimeToMomentChecked(v),
                DvbRestUtil.localeHHMMTimeToMomentChecked(b),
            ) :
            a?.minutes;

        return {
            minutes: signal(minutes),
            disabled: !editable,
            required,
        };
    });

    return {
        uiId: nextId(),
        editPermitted,
        overlayOpen: signal(false),
        kinderOrt,
        absenceTypeId: a?.absenceTypeId,
        von,
        bis,
        duration,
    };
}
