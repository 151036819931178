/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Configuration} from '@dv/shared/backend/api';
import {JaxTempBlob} from '@dv/shared/backend/model/jax-temp-blob';
import {addError$, checkPresent, DvbError} from '@dv/shared/code';
import {EMPTY, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {

    #httpClient: HttpClient = inject(HttpClient);
    #configuration: Configuration = inject(Configuration);

    public getBlob$(blob: JaxTempBlob): Observable<Blob> {
        if (blob.virusFound) {
            const error = DvbError.validationError('ERRORS.ERR_DOWNLOAD_MALWARE', undefined);
            addError$.next(error);

            return EMPTY;
        }

        return this.#httpClient.get(this.#configuration.basePath + checkPresent(blob.href), {
            responseType: 'blob',
        });
    }

    public downloadBlob(blob: Blob, filename: string): void {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(objectUrl);
    }
}
