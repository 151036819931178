import type {KitAdminState} from '@dv/kitadmin/models';
import {KinderOrt} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {RequiredPermission} from '@dv/shared/code';

export const GEMO_WIN_EXPORT = {
    name: 'base.kinderort.gemo-win-export',
    url: '/gemowin',
    data: {
        subtitle: 'KINDERORT.NAV_KINDERORT_GEMOWIN_EXPORT',
         permission(toParams: { id: string }): RequiredPermission {
            return [
                {
                    oneOf: [
                        `${PERMISSION.FEATURE.GEMO_WIN_EXPORT_RECHNUNGEN_OLTEN}:${toParams.id}`,
                        `${PERMISSION.FEATURE.GEMO_WIN_EXPORT_RECHNUNGEN_LANGENTHAL}:${toParams.id}`,
                    ],
                },
            ];
        },
    },
    paramDeclaration: {
        kinderOrt: KinderOrt,
    },
} satisfies KitAdminState;
