<div class="table-responsive dvb-kibon-betreuung-detail">
    <table class="table text-nowrap text-right">
        <caption class="sr-only" data-translate="KIBON.MUTATION.CAPTION"></caption>
        <thead>
        <tr>
            <th scope="col" data-translate="COMMON.DATE_RANGE"></th>
            <th scope="col" data-translate="KIBON.MUTATION.BETREUUNGS_KOSTEN" class="multi-line"></th>
            <th scope="col" data-translate="KIBON.MUTATION.BETREUUNGS_PENSUM"></th>

            <th ng-if="vm.hasMahlzeiten"
                scope="col"
                colspan="2"
                data-translate="KIBON.MUTATION.HAUPT_MAHLZEITEN"
                class="multi-line">
            </th>
            <th ng-if="vm.hasMahlzeiten"
                scope="col"
                colspan="2"
                data-translate="KIBON.MUTATION.NEBEN_MAHLZEITEN"
                class="multi-line">
            </th>
            <th ng-if="vm.hasEingewoehnung"
                scope="col"
                colspan="2"
                data-translate="KIBON.MUTATION.EINGEWOEHNUNG"
                class="multi-line">
            </th>

            <th scope="col">
                <dvb-kibon-betreuung-comments ng-if="vm.betreuung.comments.length > 0"
                                              popover-title="{{'KIBON.MUTATION.COMPARISON.DIFFERENT' | translate}}"
                                              comments="vm.betreuung.comments">
                    <i class="fa fa-info-circle"></i>
                </dvb-kibon-betreuung-comments>
            </th>
        </tr>
        </thead>
        <tr ng-repeat="zeitabschnitt in vm.betreuung.zeitabschnitte">
            <th scope="row" ng-bind="zeitabschnitt | dvbLimitedMonth"></th>
            <td ng-bind="zeitabschnitt.displayValue.betreuungskosten"></td>
            <td data-translate="KIBON.MUTATION.BETREUUNGS_PENSUM_MF"
                data-translate-interpolation="messageformat"
                data-translate-value-betreuungspensum="{{zeitabschnitt.displayValue.betreuungspensum}}"
                data-translate-value-pensum-unit="{{zeitabschnitt.pensumUnit}}">
            </td>

            <td ng-if="vm.hasMahlzeiten" ng-bind="zeitabschnitt.displayValue.anzahlHauptmahlzeiten"></td>
            <td ng-if="vm.hasMahlzeiten" ng-bind="zeitabschnitt.tarifProHauptmahlzeit"></td>
            <td ng-if="vm.hasMahlzeiten" ng-bind="zeitabschnitt.displayValue.anzahlNebenmahlzeiten"></td>
            <td ng-if="vm.hasMahlzeiten" ng-bind="zeitabschnitt.tarifProNebenmahlzeit"></td>
            <td ng-if="vm.hasEingewoehnung" ng-bind="zeitabschnitt.kibonExchangeEingewoehnungZeitabschnitt | dvbLimitedMonth"></td>
            <td ng-if="vm.hasEingewoehnung" ng-bind="zeitabschnitt.kibonExchangeEingewoehnungZeitabschnitt.eingewoehnungskosten"></td>

            <td>
                <dvb-kibon-betreuung-comments ng-if="zeitabschnitt.mutationState === null && zeitabschnitt.comments.length > 0"
                                              popover-title="{{'KIBON.MUTATION.GENERATION_COMMENTS' | translate}}"
                                              comments="zeitabschnitt.comments">
                    <i class="fa fa-exclamation-circle"></i>
                </dvb-kibon-betreuung-comments>
                <dvb-kibon-betreuung-comments ng-if="zeitabschnitt.mutationState === vm.comparison.DIFFERENT"
                                              popover-title="{{'KIBON.MUTATION.COMPARISON.DIFFERENT' | translate}}"
                                              comments="zeitabschnitt.comments">
                    <i class="fa fa-exclamation-circle"></i>
                </dvb-kibon-betreuung-comments>
                <dvb-kibon-betreuung-comments ng-if="zeitabschnitt.mutationState === vm.comparison.UNDEFINED"
                                              popover-title="{{'KIBON.MUTATION.COMPARISON.UNDEFINED' | translate}}"
                                              comments="zeitabschnitt.comments">
                    <i class="fa fa-question-circle"></i>
                </dvb-kibon-betreuung-comments>
            </td>
        </tr>
    </table>

    <p ng-if="vm.hasMonthly" data-translate="KIBON.MUTATION.MONTHLY_INFO"></p>
</div>
