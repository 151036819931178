/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, output, TemplateRef} from '@angular/core';
import {ButtonListComponent, ConfirmDialogComponent, SpinnerComponent} from '@dv/shared/angular';
import type {IPersisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import type {ItemWithEntity} from '../accordion.adapter';
import {AccordionComponent} from '../accordion.component';
import type {CrudAccordionTemplateContext} from './crud-accordion-template-context';
import {CrudAccordionTemplateDirective} from './crud-accordion-template.directive';

@Component({
    selector: 'dv-crud-accordion',
    standalone: true,
    imports: [
        AccordionComponent,
        ConfirmDialogComponent,
        SpinnerComponent,
        TranslocoModule,
        NgTemplateOutlet,
        CrudAccordionTemplateDirective,
        ButtonListComponent,
    ],
    templateUrl: './crud-accordion.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrudAccordionComponent<Entity extends IPersisted,
    T extends ItemWithEntity<Entity> = ItemWithEntity<Entity>> {

    public items = input.required<T[]>();

    public title = input<string | undefined>(undefined);

    public isEmpty = input<boolean>(true);
    public emptyText = input.required<string>();

    public createMode = input<boolean>(false);
    public createText = input<string | undefined>(undefined);
    public showDeleteDialog = input<boolean>(false);
    public entityName = input<string>('');

    public isLoading = input<boolean>(false);

    public headerTemplate = input.required<TemplateRef<CrudAccordionTemplateContext<T>>>();
    public editModeTemplate = input<undefined | TemplateRef<CrudAccordionTemplateContext<T>>>();
    public readonlyModeTemplate = input<undefined | TemplateRef<CrudAccordionTemplateContext<T>>>();
    public createModeTemplate = input<undefined | TemplateRef<CrudAccordionTemplateContext<T>>>();

    public readonly toggleExpanded = output<T['entity']>();
    public readonly editEntity = output<T['entity']>();
    public readonly deleteEntity = output<T['entity']>();
    public readonly confirmDelete = output<void>();
    public readonly hideDeleteDialog = output<void>();
    public readonly showCreateMode = output<void>();
}
