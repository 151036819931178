/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AppConfig} from '@dv/kitadmin/models';
import {BenutzerType} from '@dv/kitadmin/models';
import type {ConfirmDialogModel, DialogService} from '@dv/kitadmin/ui';
import type {AuthStore, BroadcastMessage, BroadcastService} from '@dv/shared/angular';
import type {IPrincipal} from '@dv/shared/authentication/model';
import {AuthEventType, Principal} from '@dv/shared/authentication/model';
import type {OidcService} from '@dv/shared/authentication/oidc';
import {IDP_HINT_QUERY_PARAM} from '@dv/shared/authentication/oidc';
import {PrincipalService} from '@dv/shared/authentication/principal';
import {AuthState, checkPresent, DvbRestUtil, isPresent, LogFactory, STANDARD_ERRORS} from '@dv/shared/code';
import type {RawParams, StateService, UIRouterGlobals} from '@uirouter/core';
import type {TargetState} from '@uirouter/core/lib/state/targetState';
import type angular from 'angular';
import moment from 'moment';
import type {Observable} from 'rxjs';
import {BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, from, of, Subject} from 'rxjs';
import {DvbRestUtilAngularJS} from 'src/app/common/service/rest/dvbRestUtilAngularJS';
import type {
    AuthorizationProviderJSAdapterService,
} from '../../authorisation/service/authorization-provider-jsadapter.service';
import type {Benutzer} from '../../benutzer/model/Benutzer';
import type {UserSettingsStore} from '../../cache/service/cache/userSettingsStore';
import {CacheStrategy} from '../../cache/service/cache/userSettingsStore';
import type {BenutzerService} from '../../common/service/rest/benutzer/benutzerService';
import {DASHBOARD_STATE} from '../../dashboard/dashboard-state';
import {SEARCH_IN_ALL_MANDANTEN} from '../../search/search-service.provider';
import {LOGIN_STATE} from '../authentication-states';
import type {UserCredentials} from '../types/UserCredentials';
import type {AuthEventService} from './auth-event.service';
import type {AuthHttpService} from './auth-http.service';
import type {HttpBuffer} from './httpBuffer';
import type {PrincipalChanged} from './kitadmin-broadcast-service-token';
import type {PrivacyPolicyService} from './privacy-policy.service';

const LOG = LogFactory.createLog('authService');

const LOCAL_STORAGE_PRINCIPAL = 'principal';

export class AuthService extends PrincipalService {
    public static override $inject: readonly string[] = [
        'authHttpService',
        'authorizationProviderJsadapterService',
        'broadcastService',
        '$q',
        '$state',
        '$uiRouterGlobals',
        '$window',
        'httpBuffer',
        'authStore',
        'userSettingsStore',
        'dialogService',
        'benutzerService',
        'oidcService',
        'privacyPolicyService',
        'authEventService',
    ];

    private principal: IPrincipal = new Principal();
    private lastUsername: string | null = null;
    private authenticated: boolean = false;
    private idpLoginEnabled: boolean = false;

    private authState$ = new BehaviorSubject<AuthState>(AuthState.UNDEFINED);

    public constructor(
        private authHttpService: AuthHttpService,
        private authorizationProviderJsadapterService: AuthorizationProviderJSAdapterService,
        private broadcastService: BroadcastService<PrincipalChanged>,
        private $q: angular.IQService,
        private $state: StateService,
        private $uiRouterGlobals: UIRouterGlobals,
        private $window: angular.IWindowService,
        private httpBuffer: HttpBuffer,
        private authStore: AuthStore,
        private userSettingsStore: UserSettingsStore,
        private dialogService: DialogService,
        private benutzerService: BenutzerService,
        private oidcService: OidcService,
        private privacyPolicyService: PrivacyPolicyService,
        private authEventService: AuthEventService,
    ) {
        super();

        this.broadcastService.messages$.subscribe({
            next: () => this.actOnUserLoggedInState(),
            error: err => LOG.warn('error in broadcast channel', err),
        });

        this.authEventService.notAuthenticated$.subscribe({
            next: () => {
                LOG.trace('recieved notAuthenticated event');
                this.authenticated = false;

                if (this.principal.userType === BenutzerType.OIDC_USER) {
                    this.oidcService.startIdpLogin();

                    return;
                }

                // If there is a login token, attempt to sign in with that instead of requiring the user to sign in
                const loginToken = this.$uiRouterGlobals.transition ?
                    this.$uiRouterGlobals.transition.params().loginToken :
                    '';
                if (loginToken) {
                    this.tokenLogin(loginToken)
                        .catch(() => this.requestLogin());

                    return;
                }

                this.requestLogin();
            },
        });

        this.oidcService.tokenReceived$.subscribe({
            next: val => {
                LOG.info('token received', val);

                const idpHint: string | null = this.oidcService.getIdpHintFromLocalStorage();
                const customParams = isPresent(idpHint) ? {[IDP_HINT_QUERY_PARAM]: idpHint} : undefined;

                firstValueFrom(this.authHttpService.oidcLogin$())
                    .then(p => this.handleLoginResponse(p))
                    .then(() => this.oidcService.clearTokens())
                    .then(() => this.forceDashboardNavigation(customParams));

                // this.oauthService.setupAutomaticSilentRefresh();
            },
            error: err => LOG.error('failed to receive token', err),
        });
    }

    public isidpLoginEnabled(): boolean {
        return this.idpLoginEnabled;
    }

    public currentAuthState$(): Observable<AuthState> {
        return this.authState$.asObservable().pipe(
            distinctUntilChanged(),
        );
    }

    /**
     * Initialization fo authService is complete. Navigation to states (and authentication / authoristion checking) may
     * commence.
     */
    public authReady$(): Observable<AuthState> {
        return this.authState$.asObservable().pipe(
            filter(state => state === AuthState.OIDC_READY || state === AuthState.AUTHENTICATED),
        );
    }

    public initWithConfig(config: AppConfig, idpHint: unknown): void {
        LOG.trace('init authentication');
        this.oidcService.setIdpHint(idpHint);

        if (isPresent(config.oidcIdentityProvider)) {
            this.idpLoginEnabled = true;
            LOG.trace('attempting OIDC initialization with idp', idpHint);
            this.authState$.next(AuthState.OIDC_STARTING);
            this.oidcService.init(config.oidcIdentityProvider)
                .catch(error => {
                    LOG.warn('could not initialize oidc service', error);
                    this.idpLoginEnabled = false;
                })
                .then(() => {
                    const restoreSuccess = this.restoreSession();
                    if (!restoreSuccess && this.idpLoginEnabled && this.oidcService.hasIdpHint()) {
                        // startIdpLogin triggers a redirect to external IDP provider: no need to release READY event
                        // since the app is about to die
                        this.oidcService.startIdpLogin();
                    } else {
                        this.authState$.next(AuthState.OIDC_READY);
                    }
                });
        } else {
            this.idpLoginEnabled = false;
            this.authState$.next(AuthState.OIDC_READY);
            this.restoreSession();
        }
    }

    public getPrincipal(): IPrincipal {
        return this.principal;
    }

    public resetPrincipal(hard: boolean = true): void {
        this.principal = new Principal();
        if (this.$window.localStorage) {
            const idpHint: string | null = this.oidcService.getIdpHintFromLocalStorage();
            this.$window.localStorage.clear();
            this.oidcService.setIdpHint(idpHint);
        }
        this.userSettingsStore.reset(CacheStrategy.SESSION, hard);
        this.authenticated = false;
        this.authState$.next(AuthState.OIDC_READY);
    }

    /**
     * @return TRUE on successful restoration
     */
    public restoreSession(): boolean {
        const p = this.readPrincipalFromStorage();
        LOG.trace('restoreSession with principal', p);

        if (p) {
            this.initWithPrincipal(p);
            this.authEventService.sendEvent({type: AuthEventType.loginRestore, payload: p});

            return true;
        }

        this.resetPrincipal();

        return false;
    }

    public isAuthenticated(): boolean {
        return this.authenticated;
    }

    public isBenutzerPrincipal(benutzer: Benutzer): boolean {
        return this.getPrincipal().userId === checkPresent(benutzer.id);
    }

    public login(userCredentials: UserCredentials): angular.IPromise<unknown> {
        const isNewPrincipal: boolean = this.isNewPrincipal(userCredentials.username ?? '');
        if (!userCredentials.username || isNewPrincipal) {
            this.resetUserSepcificData();
        }

        return firstValueFrom(this.authHttpService.usernamePasswordLogin$(userCredentials))
            .then(p => this.handleLoginResponse(p))
            .then(() => this.redirectToTargetState(isNewPrincipal));
    }

    /**
     * Posts a login request using a login token.
     */
    public tokenLogin(token: string): Promise<void> {
        return firstValueFrom(this.authHttpService.tokenLogin$(token))
            .then(p => this.handleLoginResponse(p));
    }

    public logoutAndGoToLoginPage(): angular.IPromise<unknown> {
        return this.logout()
            .then(() => this.$state.go(LOGIN_STATE.name));
    }

    public logoutAndRedirectToLoginPage(): angular.IPromise<TargetState> {
        return this.logout()
            .then(() => this.$state.target(LOGIN_STATE.name));
    }

    /**
     * Opens a dialog asking the user to accept the privacy policy.
     */
    public requirePrivacyConsent$(): Observable<boolean> {
        if (this.principal.privacyConsentGiven) {
            this.privacyPolicyService.acceptPrivacyPolicy();

            return of(true);
        }

        const confirm$: Subject<boolean> = new Subject();
        const confirm = (): Observable<unknown> =>
            from(this.$q.resolve(this.benutzerService.sendPrivacyConsent(checkPresent(this.principal.userId))
                .then(() => {
                    this.principal.privacyConsentGiven = DvbRestUtil.momentToLocalDate(moment());
                    this.writePrincipalToStorage(this.principal);
                    this.privacyPolicyService.acceptPrivacyPolicy();
                    confirm$.next(true);
                })));

        const options: ConfirmDialogModel = {
            title: 'AUTHENTICATION.PRIVACY_TITLE',
            subtitle: 'AUTHENTICATION.PRIVACY_SUBTITLE',
            confirmActionText: 'AUTHENTICATION.PRIVACY_CONFIRM',
            cancelActionText: 'AUTHENTICATION.PRIVACY_CANCEL',
            confirm,
            cancel: () => confirm$.next(false),
        };

        this.dialogService.openConfirmDialog(options);

        return confirm$;
    }

    private readPrincipalFromStorage(): IPrincipal | null {
        try {
            const parsedPrincipal = JSON.parse(this.$window.localStorage.getItem(LOCAL_STORAGE_PRINCIPAL) ?? '');

            if (Object.keys(parsedPrincipal ?? {}).length === 0) {
                return null;
            }

            return parsedPrincipal;
        } catch {
            return null;
        }
    }

    private writePrincipalToStorage(principalObj: IPrincipal): void {
        this.$window.localStorage.setItem(LOCAL_STORAGE_PRINCIPAL, JSON.stringify(principalObj));
        const message: BroadcastMessage<PrincipalChanged> = {payload: 'PrincipalChanged'};
        this.broadcastService.postMessage(message);
    }

    private isNewPrincipal(username: string): boolean {
        if (this.lastUsername && this.lastUsername !== username) {
            return true;
        }

        const principalFromStorage = this.readPrincipalFromStorage();

        if (!principalFromStorage) {
            return false;
        }

        return !principalFromStorage.username || principalFromStorage.username !== username;
    }

    private handleLoginResponse(principal: IPrincipal): void {
        LOG.trace('handeLoginResponse', principal);
        this.initWithPrincipal(principal);
        this.writePrincipalToStorage(this.principal);

        // make sure we use 'fresh' data
        DvbRestUtilAngularJS.clearHttpCache();

        if (principal.userType !== BenutzerType.OIDC_USER) {
            this.oidcService.clearTokens();
        }

        // try to reload buffered requests
        this.httpBuffer.retryAll(config => config);

        this.authState$.next(AuthState.AUTHENTICATED);
        this.authEventService.sendEvent({type: AuthEventType.loginSuccess, payload: principal});
    }

    private actOnUserLoggedInState(): void {
        LOG.trace('actOnUserLoggedInState');
        if (this.principal.username) {
            // The current window memory state has a username set, which means the user is logged in.
            // (until we figure out if he really still is by analyzing localStorage.)

            if (this.isNewPrincipal(this.principal.username)) {
                // If the current username in memory is a different one than the one in localStorage, we reject
                // every HTTP request in the buffer and follow through to resolve the session.
                this.resetUserSepcificData();
            } else {
                // The username is set and still is the same as in localStorage. Thus we don't do anything.
                return;
            }
        }

        // The username is either empty or it's a different one than before.
        // If the user is logged in, we redirect them to the dashboard. If he is logged out, we redirect them to
        // the login page.
        this.restoreSession();
        this.forceDashboardNavigation();
    }

    private requestLogin(): void {
        this.authEventService.sendEvent({type: AuthEventType.requestLogin});
    }

    private initWithPrincipal(principal: IPrincipal): void {
        this.principal = principal;
        this.lastUsername = principal.username;
        this.authenticated = true;
        this.authStore.init(this.principal.roles, this.principal.permissions);
        this.authorizationProviderJsadapterService.triggerLoginSuccess();
        this.oidcService.setIdpHint(principal.idpHint);
        this.privacyPolicyService.setPolicyAccepted(isPresent(this.principal.privacyConsentGiven));
    }

    private resetUserSepcificData(): void {
        this.httpBuffer.rejectAll(STANDARD_ERRORS.usernameChanged);
        this.userSettingsStore.reset(CacheStrategy.SESSION, true);
        this.$window.localStorage.removeItem(SEARCH_IN_ALL_MANDANTEN);
    }

    private redirectToTargetState(forceDashboard: boolean, customParams?: RawParams): angular.IPromise<unknown> {
        if (forceDashboard) {
            return this.forceDashboardNavigation(customParams);
        }

        if (this.$uiRouterGlobals.current.name !== LOGIN_STATE.name) {
            LOG.trace('pass through state', this.$uiRouterGlobals.current.name);

            return Promise.resolve();
        }

        // When we are on the login-state, redirect back to the requested state
        if (this.$uiRouterGlobals.params.toState &&
            this.$uiRouterGlobals.params.toState.name !== LOGIN_STATE.name) {

            LOG.trace('navigate to target state', this.$uiRouterGlobals.params.toState.name);

            return this.$state.go(
                this.$uiRouterGlobals.params.toState.name,
                this.$uiRouterGlobals.params.toParams);
        }

        return this.forceDashboardNavigation(customParams);
    }

    private forceDashboardNavigation(customParams?: RawParams): angular.IPromise<unknown> {
        LOG.trace('forcing navigation to dashboard');
        if (this.$uiRouterGlobals.current.name === DASHBOARD_STATE.name) {
            return this.$state.reload();
        }

        return this.$state.go(DASHBOARD_STATE.name, customParams);
    }

    private logout(): angular.IPromise<unknown> {
        this.oidcService.logout();

        return firstValueFrom(this.authHttpService.logout$())
            .then(() => {
                this.resetPrincipal(false);
                DvbRestUtilAngularJS.clearHttpCache();
            });
    }
}
