/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ErrorService} from '@dv/kitadmin/core/errors';
import type {
    BelegteEinheit,
    ExternalAnmeldungConfig,
    ExternalAnmeldungCustomField,
    ExternalAnmeldungSetup,
    WochenplanExternalAnmeldung,
} from '@dv/kitadmin/models';
import {
    ExternalAnmeldung,
    ExternalAnmeldungKind,
    ExternalKontaktperson,
    isBewerbungsplanError,
} from '@dv/kitadmin/models';
import {AddressMode} from '@dv/shared/backend/model/address-mode';
import type {JaxExternalAnmeldungCriticalConfig} from '@dv/shared/backend/model/jax-external-anmeldung-critical-config';
import type {Nullish} from '@dv/shared/code';
import {DvbUtil, isNullish, isPresent, NamedEntityType} from '@dv/shared/code';
import angular from 'angular';
import {combineLatest, Subject} from 'rxjs';
import type {PrivacyPolicyService} from '../../../authentication/service/privacy-policy.service';
import {directiveSubSink} from '../../../common/directive/directive-scope-subsink';
import type {DvbBewerbungsplanService} from '../../../common/service/dvbBewerbungsplanService';
import type {MandantConfigurationService} from '../../../mandant/service/mandantConfigurationService';
import {WizardStep} from '../../model/WizardStep';
import type {CustomStyleService} from '../../service/custom-style.service';
import type {GoogleTagManagerService} from '../../service/google-tag-manager.service';
import type {PublicExternalAnmeldungService} from '../../service/publicExternalAnmeldungService';

const componentConfig: angular.IComponentOptions = {
    transclude: true,
    bindings: {
        mandantId: '<',
        criticalConfig: '<',
    },
    template: require('./dvb-external-anmeldung.html'),
    controllerAs: 'vm',
};

export class DvbExternalAnmeldung {

    public static $inject: readonly string[] = [
        '$scope',
        'publicExternalAnmeldungService',
        'errorService',
        'dvbBewerbungsplanService',
        'mandantConfigurationService',
        'googleTagManagerService',
        'customStyleService',
        '$window',
        '$timeout',
        'privacyPolicyService',
    ];

    public mandantId!: string;
    public criticalConfig!: JaxExternalAnmeldungCriticalConfig;

    public setup?: ExternalAnmeldungSetup;
    public customFields: { [namedEntityType: string]: ExternalAnmeldungCustomField[] } = {};

    public anmeldung: ExternalAnmeldung = new ExternalAnmeldung();
    public hauptkontakt?: ExternalKontaktperson;
    public kinder: ExternalAnmeldungKind[] = [];

    public isLoading: boolean = false;
    public success: boolean = false;

    public wizardSteps: WizardStep[] = [
        new WizardStep('fa fa-child', 'COMMON.KIND.PLURAL'),
        new WizardStep('fa fa-users', 'KIND.KONTAKTE'),
        new WizardStep('custom-icon custom-icon-clipboard-check', 'EXTERNAL_ANMELDUNG.WIZARD.CONCLUSION'),
    ];
    public privacyPolicyVisible: boolean = true;
    public errorLinkVisible: boolean = false;

    private config$: Subject<ExternalAnmeldungConfig | Nullish> = new Subject<ExternalAnmeldungConfig | Nullish>();

    private static readonly SCROLL_GAP = 80;

    public constructor(
        $scope: angular.IScope,
        private readonly publicExternalAnmeldungService: PublicExternalAnmeldungService,
        private readonly errorService: ErrorService,
        private readonly dvbBewerbungsplanService: DvbBewerbungsplanService,
        private readonly mandantConfigurationService: MandantConfigurationService,
        private readonly googleTagManagerService: GoogleTagManagerService,
        private readonly customStyleService: CustomStyleService,
        private readonly $window: angular.IWindowService,
        private readonly $timeout: angular.ITimeoutService,
        private readonly privacyPolicyService: PrivacyPolicyService,
    ) {
        const subSink = directiveSubSink($scope);
        subSink.sink = combineLatest([privacyPolicyService.isPolicyAccepted$(), this.config$])
            .subscribe({
                next: ([accepted, config]) => {
                    if (accepted && isPresent(config)) {
                        this.initGoogleTagManager();
                        this.privacyPolicyVisible = false;
                    }
                },
            });
    }

    public $onInit(): void {
        this.publicExternalAnmeldungService.getSetup(this.mandantId)
            .then(setup => {
                this.setup = setup;
                this.config$.next(this.setup.config);
                this.initCustomFields();
                this.initHauptkontakt();
                this.addChild();
            });

        this.mandantConfigurationService.getDefaultCountry().then(country => {
            this.anmeldung.adresse.land = country;
        });
    }

    public $onChanges(): void {
        this.customStyleService.addStyle(this.criticalConfig?.customStyle);
    }

    public $onDestroy(): void {
        this.googleTagManagerService.stopTracking();
    }

    public initGoogleTagManager(): void {
        this.googleTagManagerService.startTracking(this.criticalConfig?.googleTagManagerContainerId);
    }

    public acceptPrivacyPolicy(): void {
        this.privacyPolicyService.acceptPrivacyPolicy();
    }

    public addKontaktperson(): ExternalKontaktperson {
        const kontaktperson = new ExternalKontaktperson();
        this.mandantConfigurationService.createDefaultAdresse().then(adresse => {
            kontaktperson.adresse = adresse;
        });
        if (this.setup?.customFields) {
            kontaktperson.initCustomFieldValues(this.customFields[NamedEntityType.KONTAKTPERSON]);
        }

        this.anmeldung.externalKontaktpersonen.push(kontaktperson);

        return kontaktperson;
    }

    public removeKontakt(kontakt: ExternalKontaktperson): void {
        DvbUtil.removeFromArray(kontakt, this.anmeldung.externalKontaktpersonen);
    }

    public validateAndSaveAnmeldung(form: angular.IFormController): void {
        const invalidSteps = this.wizardSteps.filter(step => {
            if (step.active) {
                step.validate();
            }

            return step.error || !step.complete;
        });
        this.errorLinkVisible = invalidSteps.length > 0;

        const setup = this.setup;
        const valid = invalidSteps.length === 0
            && isPresent(setup)
            && form.$valid
            && this.hasValidPlatzArt()
            && this.isAgbValid();
        this.errorService.handleValidationError(valid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!valid) {
            return;
        }

        const anmeldungen: ExternalAnmeldung[] = [];
        for (const kind of this.kinder) {

            const belegteEinheiten = this.determineBelegteEinheiten(kind);
            const validBelegteEinheiten = DvbUtil.isNotEmptyArray(belegteEinheiten);

            this.errorService.handleValidationError(validBelegteEinheiten,
                'ERRORS.ERR_REQUIRED_BELEGUNGSEINHEIT_FOR',
                {name: kind.getDisplayName()});
            if (!validBelegteEinheiten) {
                return;
            }

            const kinderOrtIds = kind.selectedKinderOrte.map(DvbUtil.mapToId);
            const kinderOrte = setup.kinderOrte.filter(k => kinderOrtIds.includes(k.id)) || [];

            anmeldungen.push(
                kind.toExternalAnmeldung(
                    kinderOrte,
                    this.anmeldung.externalKontaktpersonen,
                    belegteEinheiten,
                    this.anmeldung.bemerkung));
        }

        this.saveAnmeldungen(anmeldungen);
    }

    public addChild(): void {
        const child = new ExternalAnmeldungKind();
        if (this.setup?.customFields) {
            child.initCustomFieldValues(this.customFields[NamedEntityType.KIND]);
        }

        this.kinder.push(child);
    }

    public kinderOrteChanged(kind: ExternalAnmeldungKind): void {
        if (kind.selectedKinderOrte.length === 0) {
            kind.availableWochenplaene = [];

            return;
        }

        this.publicExternalAnmeldungService.getWochenplaene(this.mandantId, kind.selectedKinderOrte).then(plaene => {
            kind.availableWochenplaene = plaene;
            kind.selectedWochenplan = plaene.length === 1 ? plaene[0] : null;
        });
    }

    public wochenplanChanged(kind: ExternalAnmeldungKind, wochenplan: WochenplanExternalAnmeldung): void {
        kind.selectedWochenplan = wochenplan;
    }

    public navigateToError(): void {
        this.errorService.clearAll();
        let navigated = false;
        this.wizardSteps.forEach(step => {
            const haveToNavigate = !navigated && (step.error || !step.complete);
            step.active = haveToNavigate;
            navigated ||= haveToNavigate;
        });

        this.$timeout(() => {
            const invalidInput = document.querySelector('ng-form .ng-invalid');
            if (!invalidInput) {
                return;
            }

            const position = invalidInput.getBoundingClientRect();
            // add a gap because on desktop, the header would overlap a top aligned input
            this.$window.scrollTo(0, position.top + this.$window.scrollY - DvbExternalAnmeldung.SCROLL_GAP);
            (invalidInput as HTMLInputElement).focus();
        });
    }

    public stepChanged(): void {
        // reset the errorLink state to false, otherwise there is a flicker effect when submitting the form
        this.errorLinkVisible = false;
    }

    private initHauptkontakt(): void {
        this.hauptkontakt = this.addKontaktperson();
        this.hauptkontakt.hauptkontakt = true;
        this.hauptkontakt.addressMode = AddressMode.OWN;
        this.hauptkontakt.erziehungsberechtigt = true;
        this.hauptkontakt.abholberechtigt = true;
    }

    private saveAnmeldungen(anmeldungen: ExternalAnmeldung[]): void {
        this.isLoading = true;

        this.publicExternalAnmeldungService.createExternalAnmeldungen(this.mandantId, anmeldungen)
            .then(() => {
                this.success = true;
                this.errorService.handleSuccess('EXTERNAL_ANMELDUNG.KIND_SAVED');
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private isAgbValid(): boolean {
        if (!this.setup?.config?.agbUrl) {
            return true;
        }

        return this.anmeldung.agbAccepted;
    }

    private hasValidPlatzArt(): boolean {
        return this.requirePlatzArt() ? this.kinder.every(k => k.isPlatzArtSelected()) : true;
    }

    private requirePlatzArt(): boolean {
        return DvbUtil.isNotEmptyArray(this.setup?.platzarten)
            || (this.setup?.config?.showPrivaterPlatz ?? false)
            || (this.setup?.config?.showSubventionierterPlatz ?? false)
            || false;
    }

    private determineBelegteEinheiten(kind: ExternalAnmeldungKind): BelegteEinheit[] | null {
        if (isNullish(kind.selectedWochenplan)) {
            return null;
        }

        const model = kind.getSelectedWochenplanBewerbung();
        try {
            const {wochenplan, zeitraumFelder} = model;

            return this.dvbBewerbungsplanService.belegteEinheitenFromZeitraumFelder(wochenplan, zeitraumFelder);
        } catch (err) {
            if (isBewerbungsplanError(err)) {
                model.addBelegteEinheitenError(err);
                this.errorService.addValidationError(err.msgKey, err.args);
            } else {
                throw err;
            }
        }

        return null;
    }

    private initCustomFields(): void {
        this.customFields = {
            KIND: [],
            KONTAKTPERSON: [],
        };
        this.setup?.customFields?.forEach(field => {
            this.customFields[field.namedEntityType!].push(field);
        });
    }
}

componentConfig.controller = DvbExternalAnmeldung;
angular.module('kitAdmin').component('dvbExternalAnmeldung', componentConfig);
