import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {AuthorizationProviderService} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';
import {Ng2StateDeclaration, UIRouterModule} from '@uirouter/angular';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {
    ABSENCE_TYPES_STATE,
    CONFIG_STATE,
    CONTROL_STATE,
    SETTINGS_STATE,
    WORK_TIME_MODELS_STATE,
} from '../work-time-controlling.states';

@Component({
    selector: 'dv-work-time-controlling',
    standalone: true,
    imports: [
        UIRouterModule,
        TranslocoModule,
        TooltipModule,
        BsDropdownModule,

    ],
    templateUrl: './work-time-controlling.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeControllingComponent {
    public states = [CONTROL_STATE, SETTINGS_STATE];
    private dropdownStates: Ng2StateDeclaration[] = [ABSENCE_TYPES_STATE, WORK_TIME_MODELS_STATE];

    private readonly authzProvider = inject(AuthorizationProviderService);

    private hasConfigStatePermission = toSignal(this.authzProvider.hasPermission$(CONFIG_STATE.data.permission));
    public availableDropdownStates = computed(() => {
        const addConfigState = this.hasConfigStatePermission();
        const states = this.dropdownStates.slice();
        if (addConfigState) {
            states.push(CONFIG_STATE);
        }

        return states;
    });
}
