/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthStore} from '@dv/shared/angular';
import {WILDCARD_TOKEN} from '@dv/shared/angular';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {AngestellteHolidayBalance} from '@dv/shared/backend/model/angestellte-holiday-balance';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxAngestellteStandortWorkDay} from '@dv/shared/backend/model/jax-angestellte-standort-work-day';
import type {JaxAngestellteWorkDay} from '@dv/shared/backend/model/jax-angestellte-work-day';
import type {JaxAngestellteWorkDays} from '@dv/shared/backend/model/jax-angestellte-work-days';
import type {JaxPlannedAbsenceTermin} from '@dv/shared/backend/model/jax-planned-absence-termin';
import type {JaxWorkTimeControllingAbsence} from '@dv/shared/backend/model/jax-work-time-controlling-absence';
import {isNullish, isPresent} from '@dv/shared/code';

export type SortOrder = 'asc' | 'desc';
export type SortBy =
    'displayname'
    | 'expectedMinutes'
    | 'plannedMinutes'
    | 'actualMinutes'
    | 'absenceMinutes'
    | 'timeBalance'
    | 'holidayBalance';

export enum WarningType {
    MISSING_ABSENCE_TIME = 'MISSING_ABSENCE_TIME',
}

export const warningClasses: Record<WarningType, string> = {
    MISSING_ABSENCE_TIME: 'missing-absence-time',
};

export type AngestellteWorkDay = {
    date: BackendLocalDate;
    note: string | null;
    expectedMinutes: number;
    plannedMinutes: number;
    actualMinutes: number;
    absenceMinutes: number;
    timeBalance: number;
    holidayBalance: number;
    balanceCorrection: number | null;
    balanceCorrectionHolidays: number | null;
    warning: WarningType | undefined;
    standortWorkDays: AngestellteStandortWorkDay[];
    absences: WorkTimeControllingAbsence[];
    absenceTermine: JaxPlannedAbsenceTermin[];
};

export type WorkDayUiState = {
    expanded: boolean;
    isLoading: boolean;
};

export type WorkTimeControllingTableData = {
    id: string;
    angestellteDisplayName: string;
    expectedMinutes: number;
    plannedMinutes: number;
    actualMinutes: number;
    absenceMinutes: number;
    timeBalance: number;
    holidayBalance: number;
    holidayBalanceAtStartOfMonth: AngestellteHolidayBalance;
    sortedDates: BackendLocalDate[];
    workDays: Record<BackendLocalDate, AngestellteWorkDay>;
    workDaysUiState: Record<BackendLocalDate, WorkDayUiState>;
    expanded: boolean;
    availableDates?: BackendLocalDate[];
    warning?: WarningType;
    tempWorkDay?: AngestellteWorkDay;
    tempWorkDayUiState?: WorkDayUiState;
};

export type AngestellteStandortWorkDay = JaxAngestellteStandortWorkDay & { editPermitted: boolean };
export type WorkTimeControllingAbsence = JaxWorkTimeControllingAbsence & { editPermitted: boolean };

export function sumMinutes(
    controlData: JaxAngestellteWorkDays,
    accessor: (workDay: JaxAngestellteWorkDay) => number,
): number {
    return controlData.angestellteWorkDays.map(workDay => accessor(workDay))
        .reduce((a, b) => a + b, 0);
}

export function createWorkTimeControllingTableData(
    data: JaxAngestellteWorkDays[],
    {isEditPermitted, datesInMonth, frontendData}: {
        isEditPermitted: EditPermittedFunction;
        datesInMonth: BackendLocalDate[];
        frontendData?: { [angestellteId: EntityId]: WorkTimeControllingTableData };
    },
): WorkTimeControllingTableData[] {
    return data.map(d => {
        const fd = frontendData ? frontendData[d.angestellteId] : undefined;

        return createForAngestellte(d, {isEditPermitted, datesInMonth, frontendData: fd});
    });
}

export function createForAngestellte(
    backendData: JaxAngestellteWorkDays,
    {isEditPermitted, datesInMonth, frontendData}: {
        isEditPermitted: EditPermittedFunction;
        datesInMonth: BackendLocalDate[];
        frontendData?: WorkTimeControllingTableData;
        stichtag?: BackendLocalDate;
    },
): WorkTimeControllingTableData {
    let timeBalance = backendData.timeBalanceAtStartOfMonth ?? 0;
    let holidayBalance = backendData.holidayBalanceAtStartOfMonth.holidayCreditMinutes
        - backendData.holidayBalanceAtStartOfMonth.holidayAbsenceMinutes;

    const workDaysArray = backendData.angestellteWorkDays
        .sort((a, b) => a.date.localeCompare(b.date))
        .map(daily => {
            const missingAbsenceTimes = isPresent(
                daily.absences.find(a => isNullish(a.minutes) && (isNullish(a.von) || isNullish(a.bis))),
            );

            timeBalance += daily.actualMinutes
                + daily.paidAbsenceMinutes
                - daily.expectedMinutes
                + (daily.balanceCorrection ?? 0);

            holidayBalance -= daily.holidayAbsenceMinutes - (daily.balanceCorrectionHolidays ?? 0);

            const warning: WarningType | undefined = missingAbsenceTimes ?
                WarningType.MISSING_ABSENCE_TIME :
                undefined;

            const standortWorkDays = daily.standortWorkDays
                .map(day => {
                    const editPermitted = isEditPermitted(day.kinderOrtId);

                    return {...day, editPermitted};
                });

            const absences = daily.absences.map(a => {
                const editPermitted = isEditPermitted(a.kinderOrtId);

                return {...a, editPermitted};
            });

            return {
                date: daily.date,
                note: daily.note ?? null,
                expectedMinutes: daily.expectedMinutes,
                plannedMinutes: daily.plannedMinutes,
                actualMinutes: daily.actualMinutes,
                absenceMinutes: daily.absenceMinutes,
                timeBalance,
                holidayBalance,
                balanceCorrection: daily.balanceCorrection ?? null,
                balanceCorrectionHolidays: daily.balanceCorrectionHolidays ?? null,
                warning,
                standortWorkDays,
                absences,
                absenceTermine: daily.plannedAbsenceTermine,
            } satisfies AngestellteWorkDay;
        });

    const workDays = workDaysArray
        .reduce<Record<BackendLocalDate, AngestellteWorkDay>>((acc, d) => {
            acc[d.date] = d;

            return acc;
        }, {});

    const sortedDates = workDaysArray.map(d => d.date);

    const workDaysUiState = sortedDates.reduce<Record<BackendLocalDate, WorkDayUiState>>((acc, date) => {
        acc[date] = frontendData?.workDaysUiState[date] ?? {expanded: false, isLoading: false};

        return acc;
    }, {});

     const warning = workDaysArray
        .map(daily => daily.warning)
        .find(isPresent);

    return {
        workDays,
        workDaysUiState,
        sortedDates,
        id: backendData.angestellteId,
        angestellteDisplayName: backendData.angestellteDisplayName,
        expectedMinutes: sumMinutes(backendData, daily => daily.expectedMinutes),
        plannedMinutes: sumMinutes(backendData, daily => daily.plannedMinutes),
        actualMinutes: sumMinutes(backendData, daily => daily.actualMinutes),
        absenceMinutes: sumMinutes(backendData, daily => daily.absenceMinutes),
        timeBalance,
        holidayBalance,
        holidayBalanceAtStartOfMonth: backendData.holidayBalanceAtStartOfMonth,
        expanded: frontendData?.expanded ?? false,
        availableDates: determineAvailableDates(backendData, datesInMonth),
        warning,
        tempWorkDay: frontendData?.tempWorkDay,
        tempWorkDayUiState: frontendData?.tempWorkDayUiState,
    };
}

function determineAvailableDates(
    angestellteData: JaxAngestellteWorkDays,
    datesInMonth: BackendLocalDate[],
): BackendLocalDate[] {

    const datesUsed: BackendLocalDate[] = angestellteData.angestellteWorkDays.map(daily => daily.date);

    return datesInMonth.filter(dateInMonth => !datesUsed.includes(dateInMonth));
}

export type EditPermittedFunction = (kindOrtId: EntityId | undefined) => boolean;

export function editPermittedFactory(authStore: AuthStore): EditPermittedFunction {
    const editAllPermitted =
        authStore.hasPermission(PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_EDIT + WILDCARD_TOKEN);

    return (kinderOrtId: EntityId | undefined): boolean => {
        return editAllPermitted
            ||
            isPresent(kinderOrtId)
            && authStore.hasPermission(PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_EDIT + kinderOrtId);
    };
}
