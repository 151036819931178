<div class="dvb-rechnungs-status-history">
    <table class="table">
        <caption class="sr-only" data-translate="FAKTURA.STATUS_TABLE.CAPTION"></caption>
        <thead class="sr-only">
        <tr>
            <th data-translate="FAKTURA.STATUS_TABLE.STATE"></th>
            <th data-translate="FAKTURA.STATUS_TABLE.DATE"></th>
            <th data-translate="FAKTURA.STATUS_TABLE.TIME"></th>
        </tr>
        </thead>
        <tbody>
        <tr ng-repeat="update in vm.history"
            tooltip-placement="auto left"
            uib-tooltip="{{'FAKTURA.STATUS_UPDATE.STATUS_UPDATE_' + update.trigger | translate:{benutzer: update.benutzerDisplayName} }}">
            <td>
                <dvb-rechnung-status status="update.externalStatus"></dvb-rechnung-status>
            </td>
            <td ng-bind="update.time | amDateFormat:'D.M.YYYY'"></td>
            <td ng-bind="update.time | amDateFormat:'HH:mm:ss'"></td>
        </tr>
        </tbody>
    </table>
</div>
