<table class="table work-time-controlling-table" *transloco="let t">
    <caption class="sr-only">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.CAPTION') }}</caption>
    <thead>
    <tr>
        @for (header of tableHeaders; track header.translationKey) {
            <th scope="col">
                @if (header.hasOwnProperty('sortBy')) {
                    <button dvlibButton="link-button" (click)="toggleSort.emit(header.sortBy!)">
                        <span>{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.' + header.translationKey) }}</span>
                        <dvlib-icon [iconName]="sortBy() === header.sortBy ? sortOrder() : 'sort'"/>
                    </button>
                } @else {
                    {{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.' + header.translationKey) }}
                }
            </th>
        }
        <th scope="col"></th>
    </tr>
    </thead>
    @for (row of data(); track row.id) {
        <tbody [class.expanded]="row.expanded">
        <tr class="row-employee">
            <th scope="row">
                <a uiSref="base.personal.angestellte.main.anstellungen.verlauf"
                   [uiParams]="{id: row.id}">
                    {{ row.angestellteDisplayName }}
                </a>
            </th>
            <td>{{ row.expectedMinutes | dvMinutesFormat }}</td>
            <td>{{ row.plannedMinutes | dvMinutesFormat }}</td>
            <td>{{ row.actualMinutes | dvMinutesFormat }}</td>
            <td [ngClass]="row.warning? warningClasses[row.warning] : undefined"
                [tooltip]="row.warning ? t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.WARNING.' + row.warning) : undefined">
                {{ row.absenceMinutes | dvMinutesFormat }}
            </td>
            <td>{{ row.timeBalance | dvMinutesFormat }}</td>
            <td>
                @if (row.holidayBalanceAtStartOfMonth.errors.length > 0) {
                    <dv-holiday-credit-error [errors]="row.holidayBalanceAtStartOfMonth.errors"/>
                } @else {
                    {{ row.holidayBalance | dvMinutesFormat }}
                }
            </td>
            <td>
                @if (row.expanded) {
                    <button class="btn btn-link toggle"
                            [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.COLLAPSE_EMPLOYEE')"
                            [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.COLLAPSE_EMPLOYEE')"
                            (click)="toggleAngestellte.emit(row.id)">
                        <i class="fa fa-chevron-up"></i>
                    </button>
                } @else {
                    <button class="btn btn-link toggle"
                            [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.EXPAND_EMPLOYEE')"
                            [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.EXPAND_EMPLOYEE')"
                            (click)="toggleAngestellte.emit(row.id)">
                        <i class="fa fa-chevron-down"></i>
                    </button>
                }
            </td>
        </tr>
            @if (row.expanded) {
                <tr>
                    <td colspan="8" class="row-date-table">
                        <table class="table date-table">
                            <caption class="sr-only">
                                {{
                                    t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.CAPTION_ANGESTELLTE',
                                        {angestellte: row.angestellteDisplayName})
                                }}
                            </caption>
                            <thead>
                            <tr>
                                @if (row.sortedDates.length > 0) {
                                    <th scope="col">{{ row.sortedDates[0] | dvLocalDateFormat: 'MMMM YYYY' }}</th>
                                } @else {
                                    <th scope="col">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.DATE') }}</th>
                                }
                                <th scope="col">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.TARGET_HOURS') }}</th>
                                <th scope="col">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.PLANNED_HOURS') }}</th>
                                <th scope="col">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.IST') }}</th>
                                <th scope="col">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.ABSENCE') }}</th>
                                <th scope="col">{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.TIME_BALANCE') }}</th>
                                <th scope="col">
                                    <div class="divided-col">
                                        <div>{{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.HOLIDAY_BALANCE') }}</div>
                                        <div>&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                            </thead>

                            @for (date of row.sortedDates; track date) {
                                @let dayRow = row.workDays[date];
                                @let dayRowUiState = row.workDaysUiState[date];
                                <tbody [class.expanded]="dayRowUiState?.expanded">
                                <tr class="row-day">
                                    <th scope="row" class="date-cell">
                                        <span>{{ date | dvLocalDateFormat:'dd' }}</span>
                                        <span>{{ date | dvLocalDateFormat:'D.M.' }}</span>
                                    </th>
                                    <td>{{ dayRow.expectedMinutes | dvMinutesFormat }}</td>
                                    <td>{{ dayRow.plannedMinutes | dvMinutesFormat }}</td>
                                    <td>{{ dayRow.actualMinutes | dvMinutesFormat }}</td>
                                    <td [ngClass]="dayRow.warning ? warningClasses[row.warning!] : undefined"
                                        [tooltip]="dayRow.warning ? t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.WARNING.' + dayRow.warning) : undefined">
                                        {{ dayRow.absenceMinutes | dvMinutesFormat }}
                                    </td>
                                    <td>{{ dayRow.timeBalance | dvMinutesFormat }}</td>
                                    <td class="ferienkontingent">
                                        <div class="value">{{ dayRow.holidayBalance | dvMinutesFormat }}</div>
                                        @if (dayRowUiState?.expanded) {
                                            <button class="btn btn-link toggle"
                                                    [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.COLLAPSE_DAY_DETAILS')"
                                                    [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.COLLAPSE_DAY_DETAILS')"
                                                    (click)="toggleDay.emit({angestellteId: row.id, date: dayRow.date })">
                                                <i class="fa fa-chevron-up"></i>
                                            </button>
                                        } @else {
                                            <button class="btn btn-link toggle"
                                                    [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.EXPAND_DAY_DETAILS')"
                                                    [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.EXPAND_DAY_DETAILS')"
                                                    (click)="toggleDay.emit({angestellteId: row.id, date: dayRow.date })">
                                                <i class="fa fa-chevron-down"></i>
                                            </button>
                                        }
                                    </td>
                                </tr>

                                    @if (dayRowUiState?.expanded) {
                                        <!-- nested section underneath day row -->
                                        <tr dv-work-time-controlling-day-detail
                                            [angestellteId]="row.id"
                                            [workDay]="dayRow"
                                            [isLoading]="dayRowUiState.isLoading"
                                            [absenceTypes]="absenceTypes()"
                                            [kinderOrteById]="kinderOrteEntities()"
                                            [kinderOrtFraktionenById]="kinderOrtFraktionenEntities()"
                                            (save)="confirmEdit.emit($event)">
                                        </tr>
                                    }
                                </tbody>
                            }
                            @if (row.availableDates !== undefined && row.availableDates.length > 0) {
                                <tbody [ngClass]="{'expanded': row.tempWorkDay !== undefined}">
                                    @if (row.tempWorkDay !== undefined && row.tempWorkDayUiState !== undefined) {
                                        <tr dv-work-time-controlling-day-detail
                                            [angestellteId]="row.id"
                                            [workDay]="row.tempWorkDay"
                                            [isLoading]="row.tempWorkDayUiState.isLoading"
                                            [absenceTypes]="absenceTypes()"
                                            [balanceCorrection]="row.tempWorkDay.balanceCorrection"
                                            [balanceCorrectionHolidays]="row.tempWorkDay.balanceCorrectionHolidays"
                                            [kinderOrteById]="kinderOrteEntities()"
                                            [kinderOrtFraktionenById]="kinderOrtFraktionenEntities()"
                                            [forNewDay]="true"
                                            [availableDates]="row.availableDates"
                                            (abort)="removeTempDay.emit(row.id)"
                                            (save)="confirmTempDay.emit($event)">
                                        </tr>
                                    } @else {
                                        <tr *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
                                            <td colspan="7">
                                                <dvlib-button-list>
                                                    <button dvlibButton="link-button" (click)="addTempDay.emit(row.id)">
                                                        + {{ t('WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_DAY') }}
                                                    </button>
                                                </dvlib-button-list>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            }
                        </table>
                    </td>
                </tr>
            }
        </tbody>
    }
</table>
