/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {OnInit} from '@angular/core';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {Rechnung} from '@dv/kitadmin/models';
import {EmailVersandProperties, Kita} from '@dv/kitadmin/models';
import type {CompletableDialogModel} from '@dv/kitadmin/ui';
import {CompletableDialogBase} from '@dv/kitadmin/ui';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {SpfInfoComponent} from '../../../../common/component/spf-info/spf-info.component';

export interface RechnungEmailVersandBestaetigenDialogModel extends CompletableDialogModel<EmailVersandProperties> {
    rechnung: Persisted<Rechnung>;
    kita?: Kita;
}

@Component({
    selector: 'dv-rechnung-email-versand-bestaetigen',
    templateUrl: './rechnung-email-versand-bestaetigen.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        TranslocoModule,
        TooltipModule,
        LoadingButtonComponent,
        SpfInfoComponent,
        ButtonListComponent,
    ],
})
export class RechnungEmailVersandBestaetigenComponent extends CompletableDialogBase<EmailVersandProperties>
    implements RechnungEmailVersandBestaetigenDialogModel, OnInit {

    @Input() public rechnung!: Persisted<Rechnung>;
    @Input() public kita?: Kita;

    public emailVersandProperties: EmailVersandProperties = new EmailVersandProperties();
    public isLoading: boolean = false;

    public constructor(
        private readonly errorService: ErrorService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.emailVersandProperties.replyToAddress = this.kita ? this.kita.email : null;
        this.emailVersandProperties.sendEmails = true;
    }

    public submitForm(form: NgForm): void {
        this.errorService.clearAll();
        this.errorService.handleValidationError(!!form.valid, 'ERRORS.ERR_INCOMPLETE_FORM');

        if (!form.valid) {
            return;
        }

        this.submit(this.emailVersandProperties);
    }
}

