<div class="dvb-maintenance-actions">
    <div>
        <h2 data-translate="ADMINISTRATION.MAINTENANCE"></h2>
    </div>

    <div class="btn-group normal-abstand-bottom" uib-dropdown data-keyboard-nav>
        <dvb-loading-button is-loading="vm.isLoading" uib-dropdown-toggle>
            <span data-translate="ADMINISTRATION.MTNC_ACTIONS"></span>
            <span class="caret"></span>
        </dvb-loading-button>
        <ul class="dropdown-menu" uib-dropdown-menu role="menu">
            <li ng-click="vm.getAllBatchJobs()">
                <a href="" data-translate="ADMINISTRATION.MTNC_BATCH_JOB_INFORMATION"></a>
            </li>
            <li ng-click="vm.purgeBatchJobs()">
                <a href="" data-translate="ADMINISTRATION.MTNC_PURGE_BATCH_JOBS"></a>
            </li>
            <li ng-click="vm.startLeistungsrechnung()">
                <a href="" data-translate="ADMINISTRATION.MTNC_LEISTUNGSRECHNUNG_STARTEN"></a>
            </li>
            <li ng-click="vm.cleanupSchulungsMandanten()">
                <a href="" data-translate="ADMINISTRATION.MTNC_DELETE_SCHULUNGSMANDANTEN"></a>
            </li>
            <li ng-click="vm.archiveKinder()">
                <a href="" data-translate="ADMINISTRATION.MTNC_ARCHIVE_KINDER"></a>
            </li>
            <li ng-click="vm.unarchiveKinder()">
                <a href="" data-translate="ADMINISTRATION.MTNC_UNARCHIVE_KINDER"></a>
            </li>
            <li ng-click="vm.initDvbDemoData()">
                <a href="" data-translate="ADMINISTRATION.MTNC_DVB_DEMO_DATA_INIT"></a>
            </li>
            <li ng-click="vm.initWtcDemoData()">
                <a href="" data-translate="ADMINISTRATION.MTNC_WTC_DEMO_DATA_INIT"></a>
            </li>
            <li ng-click="vm.reindex()">
                <a href="" data-translate="ADMINISTRATION.MTNC_REINDEX"></a>
            </li>
            <li ng-click="vm.clearControllingData()" dvb-auth require-role="SUPER_ADMIN">
                <a href="" data-translate="ADMINISTRATION.MTNC_CLEAR_CONTROLLING_DATA"></a>
            </li>
            <li ng-click="vm.recalculateCurrentControllingData()" dvb-auth require-role="SUPER_ADMIN">
                <a href="" data-translate="ADMINISTRATION.MTNC_RECALCULATE_CURRENT_CONTROLLING_DATA"></a>
            </li>
            <li ng-click="vm.clearAuthCache()">
                <a href="" data-translate="ADMINISTRATION.MTNC_CLEAR_AUTH_CACHE"></a>
            </li>
            <li ng-click="vm.createRechnungsUeberschussTasks()" dvb-auth require-role="SUPER_ADMIN">
                <a href="" data-translate="ADMINISTRATION.MTNC_CREATE_RECHUNUNGS_UEBERSCHUSS_TASK"></a>
            </li>
            <li ng-click="vm.matchKibonExchangeData()">
                <a href="" data-translate="ADMINISTRATION.MTNC_MATCH_KIBON_EXCHANGE_DATA"></a>
            </li>
            <li ng-click="vm.shiroTestingBasics()">
                <a href="" data-translate="ADMINISTRATION.MTNC_SHIRO_TESTING_BASICS"></a>
            </li>
            <li ng-click="vm.shiroTestingSuperAdmin(true)">
                <a href="" data-translate="ADMINISTRATION.MTNC_SHIRO_TESTING_SUPER_ADMIN_BLOCKING"></a>
            </li>
            <li ng-click="vm.shiroTestingSuperAdmin(false)">
                <a href="" data-translate="ADMINISTRATION.MTNC_SHIRO_TESTING_SUPER_ADMIN_NON_BLOCKING"></a>
            </li>
            <li ng-click="vm.runAsMandant()">
                <a href="" data-translate="ADMINISTRATION.MTNC_SHIRO_RUN_AS_MANDANT"></a>
            </li>
            <li ng-click="vm.cleanupBlobData()">
                <a href="" data-translate="ADMINISTRATION.MTNC_CLEAN_UP_BLOB_DATA"></a>
            </li>
            <li ng-click="vm.reloadKibonExchangeInstitutionen()">
                <a href="" data-translate="ADMINISTRATION.MTNC_RELOAD_KIBON_EXCHANGE_INSTITUTIONEN"></a>
            </li>
            <li ng-click="vm.fixRechnungenWithPdfs()">
                <a href="" data-translate="ADMINISTRATION.MTNC_GENERATE_MISSING_RECHNUNG_PDFS"></a>
            </li>
        </ul>
    </div>

    <div ng-if="vm.lastResponse">
        <pre ng-bind="vm.lastResponse | json"></pre>
    </div>
</div>
