<div class="dv-spf-info"
     *transloco="let t"
     (mouseleave)="showPopoverSource.set(false)"
     (mouseenter)="showPopoverSource.set(true)">

    <ng-template #spfSupportInfo>
        <p [innerHTML]="t('ERRORS.SPF.TEXT')"></p>
        <p>
            <a [attr.href]="t('ERRORS.SPF.LINK_URL')"
               target="_blank"
               rel="noopener"
               [transloco]="'ERRORS.SPF.LINK_TEXT'">
            </a>
        </p>
    </ng-template>

    @if (spfError()) {
        <span class="fa fa-exclamation-triangle"
              data-testid="spf-error"
              [popover]="spfSupportInfo"
              [popoverTitle]="t('ERRORS.SPF.TITLE')"
              [isOpen]="showPopover()"
              placement="top">
        </span>
    }
</div>
